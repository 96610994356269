import RecognizedFieldValueDto from "./RecognizedFieldValueDto";
import DraftInvoiceFieldStatus from "../../constants/draftInvoiceFieldStatus";

export type DraftInvoiceAddressFields = {
  address1: string | null;
  address2: string | null;
  buildingNumber: string | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
  countryCode: string | null;
};

export type DraftInvoiceAddressFieldObject = {
  name: keyof DraftInvoiceAddressFields;
  status: DraftInvoiceFieldStatus;
  type: DraftInvoiceFieldTypes;
};

export class DraftInvoiceAddressDto {
  public readonly address1: RecognizedFieldValueDto<string | null>;

  public readonly address2: RecognizedFieldValueDto<string | null>;

  public readonly buildingNumber: RecognizedFieldValueDto<string | null>;

  public readonly city: RecognizedFieldValueDto<string | null>;

  public readonly postalCode: RecognizedFieldValueDto<string | null>;

  public readonly country: RecognizedFieldValueDto<string | null>;

  public readonly countryCode: RecognizedFieldValueDto<string | null>;

  public static readonly fields: ReadonlyArray<DraftInvoiceAddressFieldObject> =
    [
      {
        name: "address1",
        status: DraftInvoiceFieldStatus.REQUIRED,
        type: "text",
      },
      {
        name: "address2",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "text",
      },
      {
        name: "buildingNumber",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "text",
      },
      {
        name: "city",
        status: DraftInvoiceFieldStatus.REQUIRED,
        type: "text",
      },
      {
        name: "postalCode",
        status: DraftInvoiceFieldStatus.REQUIRED,
        type: "text",
      },
      {
        name: "country",
        status: DraftInvoiceFieldStatus.REQUIRED,
        type: "text",
      },
      {
        name: "countryCode",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "text",
      },
    ] as const;

  constructor(
    address1: RecognizedFieldValueDto<string | null> | null = null,
    address2: RecognizedFieldValueDto<string | null> | null = null,
    buildingNumber: RecognizedFieldValueDto<string | null> | null = null,
    city: RecognizedFieldValueDto<string | null> | null = null,
    postalCode: RecognizedFieldValueDto<string | null> | null = null,
    country: RecognizedFieldValueDto<string | null> | null = null,
    countryCode: RecognizedFieldValueDto<string | null> | null = null,
  ) {
    this.address1 = address1 ?? new RecognizedFieldValueDto();
    this.address2 = address2 ?? new RecognizedFieldValueDto();
    this.buildingNumber = buildingNumber ?? new RecognizedFieldValueDto();
    this.city = city ?? new RecognizedFieldValueDto();
    this.postalCode = postalCode ?? new RecognizedFieldValueDto();
    this.country = country ?? new RecognizedFieldValueDto();
    this.countryCode = countryCode ?? new RecognizedFieldValueDto();
  }

  public isValid(): boolean {
    return DraftInvoiceAddressDto.fields
      .filter((field) => field.status === DraftInvoiceFieldStatus.REQUIRED)
      .every((field) => this[field.name].isValid());
  }

  public updateFromFormData(formData: DraftInvoiceAddressFields): this {
    this.address1.updateFromFormData(formData.address1);
    this.address2.updateFromFormData(formData.address2);
    this.buildingNumber.updateFromFormData(formData.buildingNumber);
    this.city.updateFromFormData(formData.city);
    this.postalCode.updateFromFormData(formData.postalCode);
    this.country.updateFromFormData(formData.country);
    this.countryCode.updateFromFormData(formData.countryCode);
    return this;
  }

  static fromObject(obj): DraftInvoiceAddressDto {
    return new DraftInvoiceAddressDto(
      RecognizedFieldValueDto.fromObject(obj?.address1),
      RecognizedFieldValueDto.fromObject(obj?.address2),
      RecognizedFieldValueDto.fromObject(obj?.buildingNumber),
      RecognizedFieldValueDto.fromObject(obj?.city),
      RecognizedFieldValueDto.fromObject(obj?.postalCode),
      RecognizedFieldValueDto.fromObject(obj?.country),
      RecognizedFieldValueDto.fromObject(obj?.countryCode),
    );
  }
}

export default DraftInvoiceAddressDto;
