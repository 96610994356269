import RecognizedFieldValueDto from "./RecognizedFieldValueDto";
import DraftInvoiceFieldStatus from "../../constants/draftInvoiceFieldStatus";

export type DraftInvoiceLineItemFields = {
  identifier: string | null;
  quantity: string | number | null;
  unitPrice: string | number | null;
  vatPercentage: string | number | null;
  totalAmount: string | number | null;
  itemDescription: string | null;
  unitCode: string | null;
  discount: string | number | null;
};

export type DraftInvoiceLineItemFieldObject = {
  name: keyof DraftInvoiceLineItemFields;
  status: DraftInvoiceFieldStatus;
  type: DraftInvoiceFieldTypes;
};
export class DraftInvoiceLineItemDto {
  public readonly identifier: RecognizedFieldValueDto<string | null>;

  public readonly quantity: RecognizedFieldValueDto<number | null>;

  public readonly unitPrice: RecognizedFieldValueDto<number | null>;

  public readonly vatPercentage: RecognizedFieldValueDto<number | null>;

  public readonly totalAmount: RecognizedFieldValueDto<number | null>;

  public readonly itemDescription: RecognizedFieldValueDto<string | null>;

  public readonly unitCode: RecognizedFieldValueDto<string | null>;

  public readonly discount: RecognizedFieldValueDto<number | null>;

  public static readonly fields: ReadonlyArray<DraftInvoiceLineItemFieldObject> =
    [
      {
        name: "itemDescription",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "text",
      },
      {
        name: "identifier",
        status: DraftInvoiceFieldStatus.REQUIRED,
        type: "text",
      },
      {
        name: "quantity",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "number",
      },
      {
        name: "unitCode",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "text",
      },
      {
        name: "unitPrice",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "number",
      },
      {
        name: "discount",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "number",
      },
      {
        name: "vatPercentage",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "number",
      },
      {
        name: "totalAmount",
        status: DraftInvoiceFieldStatus.REQUIRED,
        type: "number",
      },
    ] as const;

  constructor(
    identifier: RecognizedFieldValueDto<string | null>,
    quantity: RecognizedFieldValueDto<number | null>,
    unitPrice: RecognizedFieldValueDto<number | null>,
    VATPercentage: RecognizedFieldValueDto<number | null>,
    totalAmount: RecognizedFieldValueDto<number | null>,
    itemDescription: RecognizedFieldValueDto<
      string | null
    > = new RecognizedFieldValueDto(null, 0, null, true),
    unitCode: RecognizedFieldValueDto<
      string | null
    > = new RecognizedFieldValueDto(null, 0, null, true),
    discount: RecognizedFieldValueDto<
      number | null
    > = new RecognizedFieldValueDto(null, 0, null, true),
  ) {
    this.identifier = identifier;
    this.quantity = quantity;
    this.unitPrice = unitPrice;
    this.vatPercentage = VATPercentage;
    this.totalAmount = totalAmount;
    this.itemDescription = itemDescription;
    this.unitCode = unitCode;
    this.discount = discount;
  }

  public isValid(): boolean {
    return DraftInvoiceLineItemDto.fields
      .filter((field) => field.status === DraftInvoiceFieldStatus.REQUIRED)
      .every((field) => this[field.name].isValid());
  }

  updateFromFormData(lineItem: DraftInvoiceLineItemFields): this {
    this.identifier.updateFromFormData(lineItem.identifier);
    this.quantity.updateFromFormData(Number(lineItem.quantity));
    this.unitPrice.updateFromFormData(Number(lineItem.unitPrice));
    this.vatPercentage.updateFromFormData(Number(lineItem.vatPercentage));
    this.totalAmount.updateFromFormData(Number(lineItem.totalAmount));
    this.itemDescription.updateFromFormData(lineItem.itemDescription);
    this.unitCode.updateFromFormData(lineItem.unitCode);
    this.discount.updateFromFormData(Number(lineItem.discount));
    return this;
  }

  static createFromFormData(
    lineItem: DraftInvoiceLineItemFields,
  ): DraftInvoiceLineItemDto | null {
    if (
      lineItem.identifier === "" &&
      lineItem.quantity === "" &&
      lineItem.unitPrice === "" &&
      lineItem.vatPercentage === "" &&
      lineItem.totalAmount === "" &&
      lineItem.itemDescription === "" &&
      lineItem.unitCode === "" &&
      lineItem.discount === ""
    ) {
      return null;
    }

    const getNumber = (x: string | number | null) =>
      x || x === 0 ? Number(x) : null;
    return new DraftInvoiceLineItemDto(
      new RecognizedFieldValueDto<string | null>().updateFromFormData(
        lineItem.identifier,
      ),
      new RecognizedFieldValueDto<number | null>().updateFromFormData(
        getNumber(lineItem.quantity),
      ),
      new RecognizedFieldValueDto<number | null>().updateFromFormData(
        getNumber(lineItem.unitPrice),
      ),
      new RecognizedFieldValueDto<number | null>().updateFromFormData(
        getNumber(lineItem.vatPercentage),
      ),
      new RecognizedFieldValueDto<number | null>().updateFromFormData(
        getNumber(lineItem.totalAmount),
      ),
      new RecognizedFieldValueDto<string | null>().updateFromFormData(
        lineItem.itemDescription,
      ),
      new RecognizedFieldValueDto<string | null>().updateFromFormData(
        lineItem.unitCode,
      ),
      new RecognizedFieldValueDto<number | null>().updateFromFormData(
        getNumber(lineItem.discount),
      ),
    );
  }
}

export default DraftInvoiceLineItemDto;
