import classNames from "classnames";
import styles from "./Box.module.scss";

export function Box({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={classNames(styles.box, className)}>{children}</div>;
}

export default Box;
