import { useEffect, useState } from "react";
import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEllipsis,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDto from "../../../domain/models/FileDto";
import FileProcessingStatus from "../../../domain/models/FileProcessingStatus";

import _useFiles from "../../../application/useFileList";

import {
  CommonPageLayout,
  UploadFilesButton,
  DropZone,
  UploadingFilesToast,
  DeleteFileModal,
} from "../../components";
import useFile from "../../../application/useFile";

import styles from "./MyFiles.module.scss";

const MyFiles = ({ useFiles = _useFiles, forceShowDropZone = false }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { fileList, fileListIsLoading, fileListIsFetching, refreshFileList } =
    useFiles();
  const {
    filesAreUploading,
    handleFileUploadEvent,
    uploadingFileProgress,
    numberOfUploadingFiles,
    startFilesUploading,
    reset,
  } = useFile();
  const [uploadingPanelIsOpen, setUploadingPanelIsOpen] =
    useState<boolean>(false);
  const handleClose = () => {
    setUploadingPanelIsOpen(false);
    reset();
  };
  const onDrop = (acceptedFiles: File[]) => {
    startFilesUploading(acceptedFiles);
  };

  const [fileToDelete, setFileToDelete] = useState<string>();
  const handleOpenModal = (fileId: string) => setFileToDelete(fileId);
  const handleCloseModal = () => setFileToDelete(undefined);
  const handleOnDelete = () => {
    handleCloseModal();
    refreshFileList();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => refreshFileList(), []);
  const [selectFilesDialogOpened, setSelectFilesDialogOpened] =
    useState<boolean>(false);

  const fileProcessingStatusLayoutSettings = {
    [FileProcessingStatus.Uploaded]: {
      text: t("myFilesPage.FileProcessingStatus.uploaded"),
      className: null,
    },
    [FileProcessingStatus.Processing]: {
      text: t("myFilesPage.FileProcessingStatus.processing"),
      className: null,
    },
    [FileProcessingStatus.Processed]: {
      text: t("myFilesPage.FileProcessingStatus.processed"),
      className: styles.success,
    },
    [FileProcessingStatus.UserInput]: {
      text: t("myFilesPage.FileProcessingStatus.userInput"),
      className: styles.failed,
    },
    [FileProcessingStatus.Rejected]: {
      text: t("myFilesPage.FileProcessingStatus.rejected"),
      className: styles.failed,
    },
    [FileProcessingStatus.Expired]: {
      text: t("myFilesPage.FileProcessingStatus.expired"),
      className: styles.disabled,
    },
  };
  const onFocus = () => {
    window.removeEventListener("focus", onFocus);

    setTimeout(() => setSelectFilesDialogOpened(false), 100);
  };

  const onClick = () => {
    window.addEventListener("focus", onFocus);
    setSelectFilesDialogOpened(true);
  };

  const pageLayoutParams = {
    headerText: t("myFilesPage.headerText"),
    subheaderText: "",
    CTAButton: (
      <UploadFilesButton
        onClick={onClick}
        dialogOpen={selectFilesDialogOpened}
        disabled={filesAreUploading}
        onUpload={handleFileUploadEvent}
      />
    ),
  };

  useEffect(() => {
    if (filesAreUploading) setUploadingPanelIsOpen(true);
  }, [filesAreUploading]);

  const renderSpinner = () =>
    fileListIsLoading && <Spinner inverted absolute />;

  const renderNoDataMessage = () => {
    if (fileListIsLoading || fileListIsFetching || !fileList) return null;

    if (fileList.data.length > 0) return null;

    return (
      <div className={styles.emptyList}>{t("myFilesPage.noFilesMessage")}</div>
    );
  };

  const renderStatusIcon = (status: FileProcessingStatus) => {
    switch (status) {
      case FileProcessingStatus.Processed:
        return <FontAwesomeIcon className={styles.statusIcon} icon={faCheck} />;

      case FileProcessingStatus.Expired:
        return <FontAwesomeIcon className={styles.statusIcon} icon={faCheck} />;

      case FileProcessingStatus.UserInput:
      case FileProcessingStatus.Rejected:
        return (
          <>
            <div className={styles.combinedIconContainer}>
              <FontAwesomeIcon
                className={classNames(styles.statusIcon, styles.innerIcon)}
                icon={faExclamation}
              />
            </div>
            <div className={styles.combinedIconContainer}>
              <FontAwesomeIcon className={styles.statusIcon} icon={faCircle} />
            </div>
          </>
        );

      case FileProcessingStatus.Uploaded:
      case FileProcessingStatus.Processing:
      default:
        return (
          <FontAwesomeIcon className={styles.statusIcon} icon={faEllipsis} />
        );
    }
  };

  const renderOpenFileButton = (file: FileDto, statusDefaultTxt: string) => {
    if (file.status !== FileProcessingStatus.UserInput) {
      return statusDefaultTxt;
    }

    const navigateTofile = () => navigate(`/fileViewer/${file.id}`);

    return (
      <Button onClick={navigateTofile}>{t("myFilesPage.openFile")}</Button>
    );
  };

  const renderDataRow = (entity: FileDto, index) => {
    const layoutSettings = fileProcessingStatusLayoutSettings[entity.status];
    return (
      <div
        key={index}
        className={classNames(styles.tableRow, layoutSettings.className)}
      >
        <div className={classNames(styles.tableCell, styles.iconsContainer)}>
          {renderStatusIcon(entity.status)}
        </div>
        <div className={classNames(styles.tableCell, styles.fileName)}>
          {entity.originalName}
        </div>
        <div className={styles.tableCell}>
          {new Date(entity.createdOn).toLocaleString(
            i18n.resolvedLanguage ?? "en-GB",
            {
              timeStyle: "short",
              dateStyle: "long",
              hour12: false,
            },
          )}
        </div>
        <div className={classNames(styles.tableCell, layoutSettings.className)}>
          {renderOpenFileButton(entity, layoutSettings.text)}
        </div>
        <div className={classNames(styles.tableCell, styles.iconsContainer)}>
          <Tooltip
            title={t("myFilesPage.deleteFileButtonDisclaimer")}
            placement="left"
            arrow
          >
            <IconButton
              sx={{ p: 0 }}
              aria-label="delete-file"
              onClick={() => handleOpenModal(entity.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderDataTable = () => {
    if (fileListIsLoading || !fileList) return null;

    if (fileList.data.length < 1) return null;

    return (
      <div className={styles.tableContainer}>
        <div className={styles.tableHeader}>
          <div
            className={classNames(styles.tableHeaderCell, styles.nameHeader)}
          >
            {t("myFilesPage.tableHeaders.name")}
          </div>
          <div className={styles.tableHeaderCell}>
            {t("myFilesPage.tableHeaders.date")}
          </div>
          <div
            className={classNames(styles.tableHeaderCell, styles.statusHeader)}
          >
            {t("myFilesPage.tableHeaders.status")}
          </div>
        </div>
        <div className={styles.tableBody}>
          {fileList.data.map((x, index) => renderDataRow(x, index))}
        </div>
      </div>
    );
  };

  return (
    <>
      <CommonPageLayout {...pageLayoutParams}>
        <DropZone
          onDrop={onDrop}
          text={{ dropPrompt: t("myFilesPage.dropPrompt") }}
          className={styles.dropZone}
          forceShowDropZone={forceShowDropZone}
        >
          {renderSpinner()}
          {renderNoDataMessage()}
          {renderDataTable()}
        </DropZone>
      </CommonPageLayout>
      <UploadingFilesToast
        files={uploadingFileProgress}
        numberOfUploadingFiles={numberOfUploadingFiles}
        open={uploadingPanelIsOpen}
        onClose={handleClose}
      />
      {fileToDelete && (
        <DeleteFileModal
          fileId={fileToDelete}
          isOpened={!!fileToDelete}
          onClose={handleCloseModal}
          onDelete={handleOnDelete}
        />
      )}
    </>
  );
};

export default MyFiles;
