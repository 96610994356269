import axios from "axios";
import HttpRequestError from "../domain/exceptions/HttpRequestError";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

let initialized = false;

const handleInternalSetup = (clientInstance, addToastMessage) => {
  clientInstance.interceptors.request.use(async (requestConfig) => ({
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      "x-correlation-id": crypto.randomUUID(),
    },
  }));

  clientInstance.interceptors.response.use(
    (response) => {
      const { etag, location } = response.headers;
      if (location) {
        return {
          data: {
            createdEntityId: location.substring(location.lastIndexOf("/") + 1),
          },
        };
      }

      const contentType = response.headers["content-type"];
      if (contentType && contentType.indexOf("application/json") > -1) {
        if (response.data) {
          response.data = etag ? { ...response.data, etag } : response.data;
          return response;
        }
      }

      return response;
    },
    async (error) => {
      if (error?.config?.errorHandler) {
        if (error.config.errorHandler(error.response)) {
          return Promise.resolve();
        }
      }

      if (error?.message === "canceled") return Promise.resolve();

      const message = error?.response?.data?.title ?? error?.message;
      if (message && !error?.config?.hideErrorMessage) {
        addToastMessage(message, { variant: "error" });
      }

      const exception = new HttpRequestError(error?.response.status, message);
      return Promise.reject(exception);
    },
  );
};

const setup = (addToastMessage) => {
  if (!initialized) {
    initialized = true;
    handleInternalSetup(instance, addToastMessage);
  }
};

export default {
  instance,
  setup,
};
