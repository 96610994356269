import httpClient from "../httpClient";
import authApi from "../../constants/api/authApi";

const logout = (redirectUrl: string | null = null) => {
  window.location.href = authApi.logout(redirectUrl);
};

const getUserData = async () => {
  const response = await httpClient.instance.get(authApi.userData);
  return response.data;
};

export default {
  logout,
  getUserData,
};
