import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import useSftpConnectionRepository from "../infrastructure/sftpConnection.repository";
import CreateSftpConnectionDto from "../domain/models/CreateSftpConnectionDto";
import SftpConnectionDto from "../domain/models/SftpConnectionDto";

const useSftpConnection = () => {
  const { t } = useTranslation();
  const connectionRepo = useSftpConnectionRepository();

  const { useGetConnection: useGetConnectionInternal, create, isCreating, update, isUpdating } = connectionRepo;

  const useGetConnection = (hideErrorMessage = false) => {
    return useGetConnectionInternal(hideErrorMessage);
  }

  const createConnection = async (connectionDto: CreateSftpConnectionDto) => {
    await create(connectionDto);

    enqueueSnackbar(t("sftp.successfullyConnected"), {
      variant: "success",
    });
  };

  const updateConnection = async (connection: SftpConnectionDto) => {
    await update({
      data: connection,
      connectionId: connection?.id,
      etag: connection?.etag,
    });

    enqueueSnackbar(t("sftp.successfullyUpdated"), {
      variant: "success",
    });
  }

  return {
    useGetConnection,
    createConnection,
    isCreating,
    updateConnection,
    isUpdating,
  };
};

export default useSftpConnection;
