export class RecognizedFieldValueDto<T> {
  public recognisedValue: T | null;

  public confidence: number;

  public verifiedValue: T | null;

  public isEditable: boolean;

  constructor(
    recognisedValue: T | null = null,
    confidence = 0,
    verifiedValue: T | null = null,
    isEditable = true,
  ) {
    this.recognisedValue = recognisedValue;
    this.confidence = confidence;
    this.verifiedValue = verifiedValue;
    this.isEditable = isEditable;
  }

  public updateFromFormData(value: T | null): this {
    this.verifiedValue = value;
    return this;
  }

  public isValid(): boolean {
    if (typeof this.verifiedValue === "string") {
      return this.verifiedValue !== "";
    }
    return this.verifiedValue !== null;
  }

  static fromObject<T>(
    obj: RecognizedFieldValueDto<T>,
  ): RecognizedFieldValueDto<T> {
    return new RecognizedFieldValueDto<T>(
      obj?.recognisedValue ?? null,
      obj?.confidence ?? 0,
      obj?.verifiedValue ?? null,
      obj?.isEditable ?? true,
    );
  }
}

export default RecognizedFieldValueDto;
