import api from "../../constants/api/filesApi";
import httpClient from "../httpClient";
import DraftInvoiceDto from "../../domain/models/DraftInvoiceDto";

async function get(
  draftInvoiceId: string,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<DraftInvoiceDto> {
  const requestOptions = {
    errorHandler,
    signal,
  };
  const response = await httpClient.instance.get<DraftInvoiceDto>(
    api.draftInvoice(draftInvoiceId),
    requestOptions,
  );
  return DraftInvoiceDto.createFromObject(response.data);
}

async function put(
  draftInvoiceId: string,
  data: DraftInvoiceDto,
  etag: string,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<DraftInvoiceDto> {
  const requestOptions = {
    errorHandler,
    signal,
    headers: {
      "If-Match": etag,
    },
  };
  const response = await httpClient.instance.put<DraftInvoiceDto>(
    api.draftInvoice(draftInvoiceId),
    data,
    requestOptions,
  );
  return response.data;
}

async function post(
  draftInvoiceId: string,
  data: DraftInvoiceDto,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<void> {
  const requestOptions = {
    errorHandler,
    signal,
  };
  await httpClient.instance.post<DraftInvoiceDto>(
    api.finalizeDraftInvoice(draftInvoiceId),
    data,
    requestOptions,
  );
}

export default {
  get,
  put,
  post,
};
