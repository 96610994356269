import { Children } from "react";

export default function Each<T>({
  render,
  of,
}: {
  readonly render: (item: T, index: number) => JSX.Element | null;
  readonly of: ReadonlyArray<T>;
}) {
  return <>{Children.toArray(of.map((item, index) => render(item, index)))}</>;
}
