import { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import { FieldArrayWithId, useFieldArray, useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Button, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import DraftInvoiceDto, {
  DraftInvoiceFieldObject,
  DraftInvoiceFields,
} from "../../../domain/models/DraftInvoiceDto";
import useDraftInvoice from "../../../application/useDraftInvoice";
import RecognizedFieldValueDto from "../../../domain/models/RecognizedFieldValueDto";
import { InputField } from "../InputField/InputField";
import {
  DraftInvoiceLineItemDto,
  DraftInvoiceLineItemFields,
} from "../../../domain/models/DraftInvoiceLineItemDto";
import DraftInvoiceAddressDto from "../../../domain/models/DraftInvoiceAddressDto";
import DraftInvoiceContactDto from "../../../domain/models/DraftInvoiceContactDto";
import DraftInvoiceCustomerDto from "../../../domain/models/DraftInvoiceCustomerDto";
import Each from "../../utils/Each";
import DraftInvoiceDeliveryDto from "../../../domain/models/DraftInvoiceDeliveryDto";
import DraftInvoiceSellerDto from "../../../domain/models/DraftInvoiceSellerDto";
import DraftInvoiceFieldStatus from "../../../constants/draftInvoiceFieldStatus";

type Props = {
  draftInvoice: DraftInvoiceDto;
  onSubmit?: (data: DraftInvoiceFields) => void;
  onSave?: (data: DraftInvoiceFields) => void;
};

export type DraftInvoiceEditorFormRef = {
  save: () => void;
};

const DraftInvoiceEditorForm = (
  { draftInvoice, onSubmit, onSave }: Props,
  ref: React.ForwardedRef<DraftInvoiceEditorFormRef>,
) => {
  const { t } = useTranslation();

  function getValueFromRecognizedField<T>(
    fieldValue: RecognizedFieldValueDto<T> | null | undefined,
  ) {
    return fieldValue?.verifiedValue ?? fieldValue?.recognisedValue ?? "";
  }

  function getDateOrNull(input: string | Date | null | undefined) {
    return input ? dayjs(input) : null;
  }

  function getBooleanOrNull(input: boolean | string | null | undefined) {
    if (typeof input === "boolean") return input;
    if (input?.toLowerCase() === "true") return true;
    if (input?.toLowerCase() === "false") return false;
    return null;
  }

  function getLineItems(
    invoice: DraftInvoiceDto,
  ): DraftInvoiceLineItemFields[] {
    const items = invoice.lineItems.map((lineItem) => ({
      identifier: getValueFromRecognizedField(lineItem.identifier),
      quantity: getValueFromRecognizedField(lineItem.quantity),
      unitPrice: getValueFromRecognizedField(lineItem.unitPrice),
      vatPercentage: getValueFromRecognizedField(lineItem.vatPercentage),
      totalAmount: getValueFromRecognizedField(lineItem.totalAmount),
      itemDescription: getValueFromRecognizedField(lineItem.itemDescription),
      unitCode: getValueFromRecognizedField(lineItem.unitCode),
      discount: getValueFromRecognizedField(lineItem.discount),
    }));
    return [
      ...items,
      {
        identifier: "",
        quantity: "",
        unitPrice: "",
        vatPercentage: "",
        totalAmount: "",
        itemDescription: "",
        unitCode: "",
        discount: "",
      },
    ];
  }

  const defaultValues: DraftInvoiceFields = {
    reference: getValueFromRecognizedField(draftInvoice.reference),
    totalAmount: getValueFromRecognizedField(draftInvoice.totalAmount),
    totalTaxAmount: getValueFromRecognizedField(draftInvoice.totalTaxAmount),
    issueDate: getDateOrNull(
      getValueFromRecognizedField(draftInvoice.issueDate),
    ),
    dueDate: getDateOrNull(getValueFromRecognizedField(draftInvoice.dueDate)),
    buyerReference: getValueFromRecognizedField(draftInvoice.buyerReference),
    orderReference: getValueFromRecognizedField(draftInvoice.orderReference),
    documentCurrencyCode: getValueFromRecognizedField(
      draftInvoice.documentCurrencyCode,
    ),
    customer: {
      name: getValueFromRecognizedField(draftInvoice.customer.name),
      account: getValueFromRecognizedField(draftInvoice.customer.account),
      organisationNumber: getValueFromRecognizedField(
        draftInvoice.customer.organisationNumber,
      ),
      address: {
        address1: getValueFromRecognizedField(
          draftInvoice.customer.address.address1,
        ),
        address2: getValueFromRecognizedField(
          draftInvoice.customer.address.address2,
        ),
        buildingNumber: getValueFromRecognizedField(
          draftInvoice.customer.address.buildingNumber,
        ),
        postalCode: getValueFromRecognizedField(
          draftInvoice.customer.address.postalCode,
        ),
        city: getValueFromRecognizedField(draftInvoice.customer.address.city),
        country: getValueFromRecognizedField(
          draftInvoice.customer.address.country,
        ),
        countryCode: getValueFromRecognizedField(
          draftInvoice.customer.address.countryCode,
        ),
      },
      taxIdNumber: getValueFromRecognizedField(
        draftInvoice.customer.taxIdNumber,
      ),
      gln: getValueFromRecognizedField(draftInvoice.customer.gln),
      contact: {
        phone: getValueFromRecognizedField(
          draftInvoice.customer.contact?.phone,
        ),
        email: getValueFromRecognizedField(
          draftInvoice.customer.contact?.email,
        ),
      },
    },
    lineItems: getLineItems(draftInvoice),
    creditIndicator: getBooleanOrNull(
      getValueFromRecognizedField(draftInvoice.creditIndicator),
    ),
    sellerOrderReference: getValueFromRecognizedField(
      draftInvoice.sellerOrderReference,
    ),
    paymentId: getValueFromRecognizedField(draftInvoice.paymentId),
    delivery: {
      name: getValueFromRecognizedField(draftInvoice.delivery?.name),
      date: getDateOrNull(
        getValueFromRecognizedField(draftInvoice.delivery?.date),
      ),
      address: {
        address1: getValueFromRecognizedField(
          draftInvoice.delivery?.address.address1,
        ),
        address2: getValueFromRecognizedField(
          draftInvoice.delivery?.address.address2,
        ),
        buildingNumber: getValueFromRecognizedField(
          draftInvoice.delivery?.address.buildingNumber,
        ),
        postalCode: getValueFromRecognizedField(
          draftInvoice.delivery?.address.postalCode,
        ),
        city: getValueFromRecognizedField(draftInvoice.delivery?.address.city),
        country: getValueFromRecognizedField(
          draftInvoice.delivery?.address.country,
        ),
        countryCode: getValueFromRecognizedField(
          draftInvoice.delivery?.address.countryCode,
        ),
      },
    },
    seller: {
      contactName: getValueFromRecognizedField(
        draftInvoice.seller?.contactName,
      ),
    },
  };

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<DraftInvoiceFields>({ defaultValues });

  const {
    fields: lineItemFieldArray,
    append,
    remove,
  } = useFieldArray({
    name: "lineItems",
    control,
  });

  const {
    update: updateDraftInvoice,
    finalize: finalizeDraftInvoice,
    isFinalized,
    isFinalizing,
    isUpdating,
    isLoading,
  } = useDraftInvoice();

  useImperativeHandle(ref, () => ({
    save: async () => {
      await updateDraftInvoice(getValues());
      onSave?.(getValues());
    },
  }));

  const [showToastMessage, setShowToastMessage] = useState(false);

  useEffect(() => {
    if (showToastMessage)
      enqueueSnackbar(t("draftInvoiceEditor.form.fillRequiredFields"), {
        variant: "error",
      });
  }, [showToastMessage, t]);

  if (Object.keys(errors).length > 0 && !showToastMessage) {
    setShowToastMessage(true);
  }

  const getFieldLabel = (field: string, required: boolean) => {
    let res = t(`draftInvoiceEditor.form.fields.${field}`);
    res += required ? " *" : "";
    return res;
  };

  const sectionHeader = (title?: string) => (
    <div className="w-full flex gap-2 items-center">
      <Typography variant="h6" className="text-nowrap">
        {title}
      </Typography>
      <div className="border-b-2 w-full" />
    </div>
  );

  const isLastLineItem = (index: number) =>
    index === lineItemFieldArray.length - 1;

  const isLineRequired = (index: number) =>
    !isLastLineItem(index) || lineItemFieldArray.length === 1;

  // A two way nested constructed onChange handler that
  // in the first step defines which index to use,
  // in the second it defines what regular onChange function to trigger
  // which it in the last function actually runs.
  const getOnChangeHandler = (isLastLine: boolean) => {
    return (onChange: (...event) => void) => {
      return (...event) => {
        if (isLastLine) {
          append(
            {
              identifier: "",
              quantity: "",
              unitPrice: "",
              vatPercentage: "",
              totalAmount: "",
              itemDescription: "",
              unitCode: "",
              discount: "",
            },
            { shouldFocus: false },
          );
        }
        onChange(...event);
      };
    };
  };
  const renderInvoiceLine = (
    line: FieldArrayWithId<DraftInvoiceLineItemDto>,
    index: number,
  ) => {
    const isLastLine = isLastLineItem(index);
    const requiredLine = isLineRequired(index);
    return (
      <li
        key={line.id}
        className=" shrink-0 grow grid gap-1 grid-cols-9 w-full "
      >
        <Each
          of={DraftInvoiceLineItemDto.fields}
          render={(field) => (
            <InputField
              type={field.type}
              name={`lineItems.${index}.${field.name}`}
              error={!!errors?.lineItems?.[index]?.[field.name]}
              control={control}
              required={
                field.status === DraftInvoiceFieldStatus.REQUIRED &&
                requiredLine
              }
              onChange={getOnChangeHandler(isLastLine)}
            />
          )}
        />
        <IconButton
          aria-label="delete"
          onClick={() => remove(index)}
          className="w-10 place-self-center"
          disabled={isLastLine}
        >
          <DeleteIcon />
        </IconButton>
      </li>
    );
  };

  const renderLineHeader = () => (
    <span className="flex-none grid grid-cols-9 w-full pr-4 break-words gap-1">
      <Each
        of={DraftInvoiceLineItemDto.fields}
        render={(field) => (
          <Typography variant="body1">
            {getFieldLabel(
              `lineItems.${field.name}`,
              field.status === DraftInvoiceFieldStatus.REQUIRED,
            )}
          </Typography>
        )}
      />
      <div />
    </span>
  );

  const renderInvoiceLines = () => (
    <>
      {sectionHeader(getFieldLabel("lineItems.title", false))}
      <div className="w-full flex flex-col gap-1">
        {renderLineHeader()}
        <ul className="flex flex-col gap-1 w-full min-h-10 max-h-96 overflow-y-scroll border-2 rounded">
          {lineItemFieldArray.map((field, index) =>
            renderInvoiceLine(field, index),
          )}
        </ul>
      </div>
    </>
  );

  const renderAddressFields = (required = false) => (
    <Each
      of={DraftInvoiceAddressDto.fields}
      render={(field) => {
        const isRequired =
          field.status === DraftInvoiceFieldStatus.REQUIRED && required;
        return (
          <InputField
            key={field.name}
            label={getFieldLabel(`customer.address.${field.name}`, isRequired)}
            type="text"
            name={`customer.address.${field.name}`}
            error={!!errors.customer?.address?.[field.name]}
            control={control}
            required={isRequired}
          />
        );
      }}
    />
  );

  const renderDeliveryAddressFields = (required = false) => (
    <Each
      of={DraftInvoiceAddressDto.fields}
      render={(field) => {
        const isRequired =
          field.status === DraftInvoiceFieldStatus.REQUIRED && required;

        if (field.name === "address2" || field.name === "buildingNumber")
          return null;

        return (
          <InputField
            key={field.name}
            label={getFieldLabel(`delivery.address.${field.name}`, isRequired)}
            type="text"
            name={`delivery.address.${field.name}`}
            error={!!errors.delivery?.address?.[field.name]}
            control={control}
            required={isRequired}
          />
        );
      }}
    />
  );

  const renderContactFields = (parentName: "customer") => (
    <Each
      of={DraftInvoiceContactDto.fields}
      render={(field) => (
        <InputField
          key={field.name}
          label={t(
            `draftInvoiceEditor.form.fields.${parentName}.contact.${field.name}`,
          )}
          type={field.type}
          name={`${parentName}.contact.${field.name}`}
          error={!!errors?.[parentName]?.contact?.[field.name]}
          control={control}
        />
      )}
    />
  );

  const renderCustomerFields = () => (
    <>
      {sectionHeader(getFieldLabel("customer.title", false))}
      <Each
        of={DraftInvoiceCustomerDto.fields}
        render={(field) => {
          const required = field.status === DraftInvoiceFieldStatus.REQUIRED;
          switch (field.name) {
            case "address":
              return renderAddressFields(required);
            case "contact":
              return renderContactFields("customer");
            default:
              return (
                <InputField
                  key={field.name}
                  label={getFieldLabel(`customer.${field.name}`, required)}
                  type={field.type}
                  name={`customer.${field.name}`}
                  error={!!errors.customer?.[field.name]}
                  control={control}
                  required={required}
                />
              );
          }
        }}
      />
    </>
  );

  const renderDeliveryFields = () => (
    <>
      {sectionHeader(getFieldLabel("delivery.title", false))}
      <Each
        of={DraftInvoiceDeliveryDto.fields}
        render={(field) => {
          switch (field.name) {
            case "address":
              return renderDeliveryAddressFields();
            default:
              return (
                <InputField
                  key={field.name}
                  label={t(
                    `draftInvoiceEditor.form.fields.delivery.${field.name}`,
                  )}
                  type={field.type}
                  name={`delivery.${field.name}`}
                  error={!!errors[`delivery.${field.name}`]}
                  control={control}
                />
              );
          }
        }}
      />
      {sectionHeader()}
    </>
  );

  const renderSellerFields = () => (
    <Each
      of={DraftInvoiceSellerDto.fields}
      render={(field) => (
        <InputField
          label={t(`draftInvoiceEditor.form.fields.seller.${field.name}`)}
          type={field.type}
          name={`seller.${field.name}`}
          error={!!errors[`seller.${field}`]}
          control={control}
        />
      )}
    />
  );

  const internalOnSubmit = async (data: DraftInvoiceFields) => {
    if (await finalizeDraftInvoice(data)) onSubmit?.(data);
  };

  const submitFormWrapper = (event) => {
    event.preventDefault();
    handleSubmit(internalOnSubmit)(event);
    reset((formValues) => ({ ...formValues }));
    setShowToastMessage(false);
  };

  const isSpinnerShowing = isFinalizing || isUpdating || isLoading;
  const renderSpinner = () => {
    if (isSpinnerShowing) {
      return <Spinner />;
    }

    return null;
  };

  const dynamicallyRenderFields = () => (
    <>
      {renderSpinner()}
      <form
        className="p-6 flex flex-wrap gap-4 w-full bg-white border border-[#e5e7eb] rounded-r-[8px]"
        onSubmit={submitFormWrapper}
      >
        <Each
          of={DraftInvoiceDto.fields}
          render={(field: DraftInvoiceFieldObject) => {
            const isRequired =
              field.status === DraftInvoiceFieldStatus.REQUIRED;
            switch (field.name) {
              case "lineItems":
                return renderInvoiceLines();
              case "customer":
                return renderCustomerFields();
              case "delivery":
                return renderDeliveryFields();
              case "seller":
                return renderSellerFields();
              default:
                return (
                  <InputField
                    label={getFieldLabel(field.name, isRequired)}
                    type={field.type}
                    name={field.name}
                    error={!!errors[field.name]}
                    control={control}
                    required={isRequired}
                  />
                );
            }
          }}
        />
        <section className="w-full flex justify-center">
          <Button
            variant="contained"
            size="large"
            type="submit"
            title={t("draftInvoiceEditor.form.submitInvoice")}
            disabled={isFinalized || isFinalizing}
            onClick={submitFormWrapper}
          >
            {t("common.submit")}
          </Button>
        </section>
      </form>
    </>
  );

  return dynamicallyRenderFields();
};

export default forwardRef(DraftInvoiceEditorForm);
