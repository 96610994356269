import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SelectLanguagePopper } from "@zwapgrid/zwapgrid-ui-component-library";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import styles from "./MainContainer.module.scss";

import Header from "../Header";
import NavigationBar from "../NavigationBar";
import Footer from "../Footer";
import languages from "../../../constants/languages";
import useLogout from "../../../application/auth/useLogout";

const MainContainer = ({ children }) => {
  const [isNavigationBarOpened, setIsNavigationBarOpened] = useState(false);
  const { logoutWithRedirect } = useLogout();

  const location = useLocation();

  useEffect(() => {
    setIsNavigationBarOpened(false);
  }, [location]);

  const toggleNavigationBar = () =>
    setIsNavigationBarOpened(!isNavigationBarOpened);
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.appContainer}>
      <Header>
        <div className={styles.header}>
          <Button
            className={styles.burgerBtn}
            onClick={toggleNavigationBar}
            title={t("common.toggleNavigation")}
          >
            <div className={styles.burgerBtnStrip} />
            <div className={styles.burgerBtnStrip} />
            <div className={styles.burgerBtnStrip} />
          </Button>
          <Button
            className={styles.btnLogoutHeader}
            onClick={logoutWithRedirect}
            title={t("common.logout")}
          >
            {t("common.logout")}
          </Button>
        </div>
      </Header>
      <div className={styles.mainContainer}>
        <NavigationBar isOpened={isNavigationBarOpened} />
        <div className={styles.pageContentContainer}>
          <div className={styles.controlsContainer}>
            <SelectLanguagePopper
              availableLanguages={languages}
              activeLanguage={i18n.resolvedLanguage?.split("-")[0] ?? "en"}
              onLanguageChange={i18n.changeLanguage}
            />
            <Button
              className={styles.btnLogout}
              onClick={logoutWithRedirect}
              title={t("common.logout")}
            >
              {t("common.logout")}
            </Button>
          </div>
          <div className={styles.pageContent}>{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MainContainer;
