import { useQuery, useMutation, useIsMutating } from "@tanstack/react-query";
import _fileService from "./services/fileService";

import UploadFileMutationDto from "./dtos/UploadFileMutationDto";
import useAuth from "../application/auth/useAuth";

const uploadFileMutationKey = "uploadFile";
const deleteFileMutationKey = "deleteFile";

const useGetFile = (fileId, fileService = _fileService) => {
  const { user } = useAuth();

  const { isLoading, isFetching, data, error } = useQuery({
    queryKey: ["uploadedFile", fileId],
    queryFn: async () => {
      const file = await fileService.getFile(fileId);
      file.storagePath = file.storagePath?.replace("v1/", "");
      return file;
    },
    enabled: !!user?.consentId && !!fileId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  return {
    file: data,
    fileIsFetching: isLoading || isFetching,
    fetchFileError: error,
  };
};

const useFileRepository = (fileService = _fileService) => {
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: [uploadFileMutationKey],
    mutationFn: async (parameters: UploadFileMutationDto) =>
      fileService.uploadFile(parameters.file, parameters.progressCallBack),
  });

  const { mutateAsync: deleteFileAsync, isLoading: fileIsDeleting } =
    useMutation({
      mutationKey: [deleteFileMutationKey],
      mutationFn: async (fileId: string) => fileService.deleteFile(fileId),
    });

  async function uploadFile(
    file: FormData,
    progressCallBack:
      | ((progressEvent: ProgressEvent) => void)
      | undefined = undefined,
  ) {
    const mutationParameters = new UploadFileMutationDto(
      file,
      progressCallBack,
    );
    return mutateAsync(mutationParameters);
  }

  return {
    uploadFile,
    fileIsUploading: isLoading,
    filesAreUploading: !!useIsMutating([uploadFileMutationKey]),
    useGetFile,
    deleteFileAsync,
    fileIsDeleting,
  };
};

export default useFileRepository;
