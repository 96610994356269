import { useSnackbar } from "notistack";
import {
  Route,
  Routes,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import httpClient from "../../../infrastructure/httpClient";

import MainContainer from "../../components/MainContainer";
import MyFiles from "../MyFiles";
import FileViewerPage from "../FileViewerPage";
import UnauthorizedPage from "../RedirectPage/UnauthorizedPage";
import ConnectToSftp from "../ConnectToSftp";
import ProtectedRoute from "../../../application/auth/ProtectedRoute";
import useAuth from "../../../application/auth/useAuth";
import languages from "../../../constants/languages";
import sharedConsts from "../../../constants/SharedConsts";
import useFeatureList from "../../../application/useFeatureList";

const ProtectedMainContainer = ProtectedRoute(MainContainer);
const ProtectedFileViewerPage = ProtectedRoute(FileViewerPage);

const Root = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userIsFetching } = useAuth();
  const { i18n } = useTranslation();
  const [urlParams] = useSearchParams();
  const { features } = useFeatureList();

  httpClient.setup(enqueueSnackbar);

  useEffect(() => {
    urlParams.forEach((value, key) => {
      if (
        key.toLowerCase() === sharedConsts.languageParamKey &&
        languages.find((lang) => lang === value)
      ) {
        i18n.changeLanguage(value).then(() => {
          urlParams.delete(sharedConsts.languageParamKey);
        });
      }
    });
  }, [urlParams, i18n]);

  if (userIsFetching) {
    return <Spinner />;
  }

  const routes = [
    {
      id: "fileone-myFiles",
      path: "/files",
      element: <MyFiles />,
    },
    {
      id: "fileone-sftp-connection",
      path: "/sftp",
      element: <ConnectToSftp />,
    },
    {
      id: "default",
      path: "*",
      element: (
        <Navigate to={{ pathname: "/files", search: urlParams.toString() }} />
      ),
    },
  ];

  const filterRoutes = (id: string) => {
    if (!features) {
      return true;
    }

    if (features.findIndex((f) => f.key === id) === -1) {
      return true;
    }

    return features.find((f) => f.key === id)?.value;
  };

  const filteredRoutes = routes.filter((l) => filterRoutes(l.id));

  const renderMainContainer = () => (
    <ProtectedMainContainer>
      <Routes>
        {filteredRoutes.map((x) => (
          <Route key={x.id} path={x.path} element={x.element} />
        ))}
      </Routes>
    </ProtectedMainContainer>
  );

  return (
    <Routes>
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="/fileViewer/:fileId" element={<ProtectedFileViewerPage />} />
      <Route path="*" element={renderMainContainer()} />
    </Routes>
  );
};

export default Root;
