import { useTranslation } from "react-i18next";
import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useFile from "../../../application/useFile";

type Props = {
  fileId: string;
  isOpened: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteFileModal = ({ fileId, isOpened, onClose, onDelete }: Props) => {
  const { t } = useTranslation();
  const { deleteFileAsync, fileIsDeleting } = useFile();

  const handleDelete = async () => {
    if (!fileIsDeleting) {
      try {
        await deleteFileAsync(fileId);
        onDelete();
      } catch (error) {
        onClose();
      }
    }
  };

  return (
    <>
      <Modal open={isOpened}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: "1.5rem",
            backgroundColor: "white",
            borderRadius: "18px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography sx={{ mb: 4, fontWeight: "bold", fontSize: "1.5rem" }}>
            {t("myFilesPage.deleteFileModal.title")}
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            aria-label="delete-file"
            size="large"
            onClick={onClose}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Typography sx={{ mb: 2, fontSize: "0.9rem" }}>
            {t("myFilesPage.deleteFileModal.description")}
          </Typography>
          <Button
            variant="contained"
            color="error"
            disabled={fileIsDeleting}
            onClick={handleDelete}
          >
            {t("myFilesPage.deleteFileModal.deleteButtonTitle")}
          </Button>
        </Box>
      </Modal>
      {fileIsDeleting && <Spinner />}
    </>
  );
};

export default DeleteFileModal;
