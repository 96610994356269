import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import DraftInvoiceDto, {
  DraftInvoiceFields,
} from "../domain/models/DraftInvoiceDto";
import { useDraftInvoiceRepository } from "../infrastructure/draftInvoice.repository";
import useAuth from "./auth/useAuth";

const useDraftInvoice = () => {
  const { user } = useAuth();
  const { fileId } = useParams();
  const repo = useDraftInvoiceRepository({ draftInvoiceId: fileId });
  const { t } = useTranslation();

  const {
    draftInvoice,
    isLoading,
    isError,
    isUpdating,
    isFetching,
    isFinalizing,
    isFinalized,
  } = repo;

  const update = async (formData: DraftInvoiceFields) => {
    if (!user?.consentId || !fileId || !draftInvoice) return;
    const data =
      DraftInvoiceDto.createFromObject(draftInvoice).updateFromFormData(
        formData,
      );
    await repo.update(data, fileId);
    enqueueSnackbar(t("useDraftInvoice.updateSuccessful"), {
      variant: "success",
    });
  };

  const finalize = async (formData: DraftInvoiceFields) => {
    if (!draftInvoice || !user?.consentId || !fileId) return false;
    const data =
      DraftInvoiceDto.createFromObject(draftInvoice).updateFromFormData(
        formData,
      );
    if (!data?.isValid()) {
      enqueueSnackbar(t("useDraftInvoice.finalizeError"), { variant: "error" });
      return false;
    }

    await repo.update(data, fileId);
    await repo.finalize(data, fileId);
    enqueueSnackbar(t("useDraftInvoice.finalizeSuccessful"), {
      variant: "success",
    });
    return true;
  };

  return {
    draftInvoice,
    isLoading,
    isError,
    update,
    finalize,
    isUpdating,
    isFetching,
    isFinalizing,
    isFinalized,
  };
};

export default useDraftInvoice;
