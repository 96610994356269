import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import _sftpConnectionService from "./services/sftpConnectionService";
import SftpConnectionDto from "../domain/models/SftpConnectionDto";
import CreateSftpConnectionDto from "../domain/models/CreateSftpConnectionDto";

const sftpConnectionQueryKey = "sftpConnection";
const createSftpConnectionKey = "createSftpConnection";
const updateSftpConnectionQueryKey = "updateSftpConnection";

const useGetConnection = (
  hideErrorMessage = false,
  sftpConnectionService = _sftpConnectionService) => {

  const { data, isLoading, isFetching } = useQuery<SftpConnectionDto,Error>(
    [sftpConnectionQueryKey],
    () =>
      sftpConnectionService.getConnection(hideErrorMessage),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  );

  return {
    connection: data,
    connectionIsFetching: isLoading || isFetching,
  };
}

const useSftpConnectionRepository = (
  sftpConnectionService = _sftpConnectionService,
) => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationKey: [createSftpConnectionKey],
    mutationFn: async (connection: CreateSftpConnectionDto) =>
      sftpConnectionService.createConnection(connection),
    onSuccess: async () =>
      queryClient.invalidateQueries([sftpConnectionQueryKey]),
  });

  const updateMutation = useMutation({
    mutationKey: [updateSftpConnectionQueryKey],
    mutationFn: async (parameters: {
      data: SftpConnectionDto;
      etag: string;
      connectionId: string;
    }) =>
      sftpConnectionService.updateConnection(
        parameters.connectionId,
        parameters.data,
        parameters.etag,
      ),
    onSuccess: async () =>
      queryClient.invalidateQueries([sftpConnectionQueryKey]),
  });

  return {
    create: createMutation.mutateAsync,
    isCreating: createMutation.isLoading,
    update: updateMutation.mutateAsync,
    isUpdating: updateMutation.isLoading,
    useGetConnection,
  };
};

export default useSftpConnectionRepository;
