import api from "../../constants/api/filesApi";
import fileConsts from "../../constants/fileConsts";
import httpClient from "../httpClient";
import FileDto from "../../domain/models/FileDto";
import PagedResultDto from "../../domain/models/PagedResultDto";

async function getFile(
  fileId: string,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<FileDto> {
  const requestOptions = {
    errorHandler,
    signal,
  };
  const response = await httpClient.instance.get<FileDto>(
    api.file(fileId),
    requestOptions,
  );
  return response.data;
}

async function getFiles(
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<PagedResultDto<FileDto>> {
  const requestOptions = {
    errorHandler,
    signal,
  };
  const response = await httpClient.instance.get<PagedResultDto<FileDto>>(
    api.files(),
    requestOptions,
  );
  return response.data;
}

async function uploadFile(
  file: FormData,
  progressCallBack:
    | ((progressEvent: ProgressEvent) => void)
    | undefined = undefined,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<PagedResultDto<FileDto>> {
  const requestOptions = {
    errorHandler,
    signal,
    onUploadProgress: (progressEvent: ProgressEvent) =>
      progressCallBack?.(progressEvent),
    hideErrorMessage: true,
    transformRequest: [(data) => data],
  };

  file.forEach((value) => {
    const fileValue = value as File;

    if (fileValue.size > fileConsts.maxFileSize) {
      throw Error("File exceed maximum file size limit: 50mb");
    }
    if (fileValue.type !== "application/pdf" && fileValue.type !== "text/xml") {
      throw Error("Currently we support only pdf and xml files.");
    }
  });

  const response = await httpClient.instance.post(
    api.files(),
    file,
    requestOptions,
  );
  return response.data;
}

async function deleteFile(
  fileId: string,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
) {
  const requestOptions = {
    errorHandler,
    signal,
  };
  const response = await httpClient.instance.delete(
    api.file(fileId),
    requestOptions,
  );
  return response.data;
}

export default {
  getFile,
  getFiles,
  uploadFile,
  deleteFile,
};
