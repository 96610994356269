import { useQuery } from '@tanstack/react-query';
import _consentService from './services/consentService';
import useAuth from '../application/auth/useAuth';

const useConsentRepository = (
  consentService = _consentService,
) => {
  const { user } = useAuth();

  const { isLoading: consentIsFetching, data: consent, error: fetchConsentError } = useQuery({
    queryKey: ['consent', user?.consentId],
    queryFn: () => consentService.getConsent(user?.consentId),
    enabled: !!user?.consentId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  return {
    consent,
    consentIsFetching,
    fetchConsentError,
  };
};

export default useConsentRepository;
