import api from "../../constants/api/sftpConnectionApi";
import httpClient from "../httpClient";
import CreateSftpConnectionDto from "../../domain/models/CreateSftpConnectionDto";
import SftpConnectionDto from "../../domain/models/SftpConnectionDto";

async function getConnection(
  hideErrorMessage = false,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<SftpConnectionDto> {
  const requestOptions = {
    hideErrorMessage,
    errorHandler,
    signal,
  };
  const response = await httpClient.instance.get<SftpConnectionDto>(
    api.sftpConnections(),
    requestOptions,
  );
  return response.data;
}

async function createConnection(
  data: CreateSftpConnectionDto,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<void> {
  const requestOptions = {
    errorHandler,
    signal,
  };
  const response = await httpClient.instance.post(
    api.sftpConnections(),
    data,
    requestOptions,
  );
  return response.data;
}

async function updateConnection(
  connectionId: string,
  data: CreateSftpConnectionDto,
  etag: string,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<void> {
  const requestOptions = {
    errorHandler,
    signal,
    headers: {
      "If-Match": etag,
    },
  };
  const response = await httpClient.instance.put(
    api.sftpConnection(connectionId),
    data,
    requestOptions,
  );
  return response.data;
}

export default {
  getConnection,
  createConnection,
  updateConnection,
};
