import RecognizedFieldValueDto from "./RecognizedFieldValueDto";
import DraftInvoiceFieldStatus from "../../constants/draftInvoiceFieldStatus";

export type DraftInvoiceContactFields = {
  phone: string | null;
  email: string | null;
};

export type DraftInvoiceContactFieldObject = {
  name: keyof DraftInvoiceContactFields;
  status: DraftInvoiceFieldStatus;
  type: DraftInvoiceFieldTypes;
};

export default class DraftInvoiceContactDto {
  public phone: RecognizedFieldValueDto<string | null>;

  public email: RecognizedFieldValueDto<string | null>;

  public static readonly fields: ReadonlyArray<DraftInvoiceContactFieldObject> =
    [
      { name: "phone", status: DraftInvoiceFieldStatus.OPTIONAL, type: "text" },
      { name: "email", status: DraftInvoiceFieldStatus.OPTIONAL, type: "text" },
    ] as const;

  constructor(
    phone: RecognizedFieldValueDto<string | null> | null = null,
    email: RecognizedFieldValueDto<string | null> | null = null,
  ) {
    this.phone = phone ?? new RecognizedFieldValueDto();
    this.email = email ?? new RecognizedFieldValueDto();
  }

  public static fromObject(obj): DraftInvoiceContactDto {
    return new DraftInvoiceContactDto(
      RecognizedFieldValueDto.fromObject(obj.phone),
      RecognizedFieldValueDto.fromObject(obj.email),
    );
  }

  public isValid() {
    return DraftInvoiceContactDto.fields
      .filter((field) => field.status === DraftInvoiceFieldStatus.REQUIRED)
      .every((field) => this[field.name].isValid());
  }

  public updateFromFormData(formData: DraftInvoiceContactFields): this {
    this.phone.updateFromFormData(formData.phone);
    this.email.updateFromFormData(formData.email);
    return this;
  }
}
