import RecognizedFieldValueDto from "./RecognizedFieldValueDto";
import DraftInvoiceAddressDto, {
  DraftInvoiceAddressFields,
} from "./DraftInvoiceAddressDto";
import DraftInvoiceContactDto, {
  DraftInvoiceContactFields,
} from "./DraftInvoiceContactDto";
import DraftInvoiceFieldStatus from "../../constants/draftInvoiceFieldStatus";

export type DraftInvoiceCustomerFields = {
  name: string | null;
  account: string | null;
  organisationNumber: string | null;
  address: DraftInvoiceAddressFields;
  taxIdNumber: string | null;
  gln: string | null;
  contact: DraftInvoiceContactFields;
};

export type DraftInvoiceCustomerFieldObject = {
  name: keyof DraftInvoiceCustomerFields;
  status: DraftInvoiceFieldStatus;
  type: DraftInvoiceFieldTypes;
};
export class DraftInvoiceCustomerDto {
  public readonly account: RecognizedFieldValueDto<string | null>;

  public readonly organisationNumber: RecognizedFieldValueDto<string | null>;

  public readonly name: RecognizedFieldValueDto<string | null>;

  public readonly address: DraftInvoiceAddressDto;

  public readonly taxIdNumber: RecognizedFieldValueDto<string | null>;

  public readonly gln: RecognizedFieldValueDto<string | null>;

  public readonly contact: DraftInvoiceContactDto;

  public static readonly fields: ReadonlyArray<DraftInvoiceCustomerFieldObject> =
    [
      {
        name: "account",
        status: DraftInvoiceFieldStatus.REQUIRED,
        type: "text",
      },
      {
        name: "organisationNumber",
        status: DraftInvoiceFieldStatus.REQUIRED,
        type: "text",
      },
      {
        name: "name",
        status: DraftInvoiceFieldStatus.REQUIRED,
        type: "text",
      },
      {
        name: "address",
        status: DraftInvoiceFieldStatus.REQUIRED,
        type: "object",
      },
      {
        name: "taxIdNumber",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "text",
      },
      {
        name: "gln",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "text",
      },
      {
        name: "contact",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "object",
      },
    ] as const;

  constructor(
    account: RecognizedFieldValueDto<string | null>,
    organisationNumber: RecognizedFieldValueDto<string | null>,
    name: RecognizedFieldValueDto<string | null>,
    address: DraftInvoiceAddressDto,
    taxIdNumber: RecognizedFieldValueDto<string | null> | null = null,
    gln: RecognizedFieldValueDto<string | null> | null = null,
    contact: DraftInvoiceContactDto | null = null,
  ) {
    this.account = account;
    this.organisationNumber = organisationNumber;
    this.name = name;
    this.address = address;
    this.taxIdNumber = taxIdNumber ?? new RecognizedFieldValueDto();
    this.gln = gln ?? new RecognizedFieldValueDto();
    this.contact = contact ?? new DraftInvoiceContactDto();
  }

  public isValid(): boolean {
    return DraftInvoiceCustomerDto.fields
      .filter((field) => field.status === DraftInvoiceFieldStatus.REQUIRED)
      .every((field) => this[field.name].isValid());
  }

  public updateFromFormData(formData: DraftInvoiceCustomerFields): this {
    this.account.updateFromFormData(formData.account);
    this.organisationNumber.updateFromFormData(formData.organisationNumber);
    this.name.updateFromFormData(formData.name);
    this.address.updateFromFormData(formData.address);
    this.taxIdNumber.updateFromFormData(formData.taxIdNumber);
    this.gln.updateFromFormData(formData.gln);
    this.contact.updateFromFormData(formData.contact);

    return this;
  }

  static fromObject(obj): DraftInvoiceCustomerDto {
    return new DraftInvoiceCustomerDto(
      RecognizedFieldValueDto.fromObject(obj?.account),
      RecognizedFieldValueDto.fromObject(obj?.organisationNumber),
      RecognizedFieldValueDto.fromObject(obj?.name),
      DraftInvoiceAddressDto.fromObject(obj?.address),
      RecognizedFieldValueDto.fromObject(obj?.taxIdNumber),
      RecognizedFieldValueDto.fromObject(obj?.gln),
      DraftInvoiceContactDto.fromObject(obj?.contact),
    );
  }
}

export default DraftInvoiceCustomerDto;
