import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DraftInvoiceDto } from "../domain/models/DraftInvoiceDto";
import DraftInvoiceService from "./services/draftInvoiceService";

const updateDraftInvoiceMutationKey = "updateDraftInvoice";
const finalizeDraftInvoiceMutationKey = "finalizeDraftInvoice";

export const draftInvoiceQueryKey = "draftInvoice";
interface Props {
  draftInvoiceId: string | undefined;
}
export const useDraftInvoiceRepository = (props: Props) => {
  const service = DraftInvoiceService;
  const queryClient = useQueryClient();

  const { data, isLoading, isError, isFetched, isFetching } = useQuery<
    DraftInvoiceDto,
    Error
  >(
    [draftInvoiceQueryKey, props.draftInvoiceId],
    () =>
      service
        .get(props.draftInvoiceId as string)
        .then((response) => {
          const invoice = Object.setPrototypeOf(
            response,
            DraftInvoiceDto.prototype,
          );
          if (response.totalAmount.recognisedValue !== null)
            invoice.totalAmount.recognisedValue = Object.setPrototypeOf(
              response.totalAmount.recognisedValue,
              Number.prototype,
            );
          if (response.totalAmount.verifiedValue !== null)
            invoice.totalAmount.verifiedValue = Object.setPrototypeOf(
              response.totalAmount.verifiedValue,
              Number.prototype,
            );

          if (response.issueDate.recognisedValue)
            invoice.issueDate.recognisedValue = new Date(
              response.issueDate.recognisedValue,
            );
          if (response.issueDate.verifiedValue)
            invoice.issueDate.verifiedValue = new Date(
              response.issueDate.verifiedValue,
            );
          if (response.dueDate.recognisedValue)
            invoice.dueDate.recognisedValue = new Date(
              response.dueDate.recognisedValue,
            );
          if (response.dueDate.verifiedValue)
            invoice.dueDate.verifiedValue = new Date(
              response.dueDate.verifiedValue,
            );

          return invoice;
        }),
    {
      enabled: !!props.draftInvoiceId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  );

  const updateMutation = useMutation({
    mutationKey: [updateDraftInvoiceMutationKey],
    mutationFn: (context: {
      data: DraftInvoiceDto;
      etag: string;
      draftInvoiceId: string;
    }) =>
      service.put(
        context.draftInvoiceId,
        context.data,
        context.etag,
      ),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([
        draftInvoiceQueryKey,
        variables.draftInvoiceId,
      ]);
    },
  });

  async function update(
    draftInvoice: DraftInvoiceDto,
    draftInvoiceId: string,
  ) {
    return updateMutation.mutateAsync({
      data: draftInvoice,
      etag: draftInvoice?.etag,
      draftInvoiceId,
    });
  }

  const finalizeMutation = useMutation({
    mutationKey: [finalizeDraftInvoiceMutationKey],
    mutationFn: (context: {
      data: DraftInvoiceDto;
      draftInvoiceId: string;
    }) =>
      service.post(context.draftInvoiceId, context.data),
  });

  async function finalize(
    draftInvoice: DraftInvoiceDto,
    draftInvoiceId: string,
  ) {
    return finalizeMutation.mutateAsync({
      data: draftInvoice,
      draftInvoiceId,
    });
  }

  return {
    draftInvoice: data,
    isFetched,
    isLoading,
    isFetching,
    isError,
    update,
    isUpdating: updateMutation.isLoading,
    isUpdated: updateMutation.isSuccess,
    finalize,
    isFinalizing: finalizeMutation.isLoading,
    isFinalized: finalizeMutation.isSuccess,
  };
};

export default useDraftInvoiceRepository;
