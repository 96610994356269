import { useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import _authService from "./services/authService";

export const userQueryKey = "user";
const useUserRepository = (authService = _authService) => {
  const navigate = useNavigate();

  const redirectToUnauthorized = () => navigate("/unauthorized");

  const {
    isLoading: userIsFetching,
    data: user,
    error: fetchUserError,
  } = useQuery({
    queryKey: [userQueryKey],
    queryFn: () => authService.getUserData(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  return {
    user,
    userIsFetching,
    fetchUserError,
    isAuthenticated: !!user?.isAuthenticated,

    redirectToUnauthorized,
    logout: authService.logout,
  };
};

export default useUserRepository;
