import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import { useRef, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import useDraftInvoice from "../../../application/useDraftInvoice";
import DraftInvoiceEditorForm, {
  DraftInvoiceEditorFormRef,
} from "./DraftInvoiceEditorForm";

type Props = {
  onSave?: () => void;
  onSubmit?: () => void;
};

const DraftInvoiceEditor = (
  { onSubmit, onSave }: Props,
  ref: React.ForwardedRef<DraftInvoiceEditorFormRef>,
) => {
  const formRef = useRef<DraftInvoiceEditorFormRef>(null);
  const { t } = useTranslation();
  const { draftInvoice, isLoading, isError } = useDraftInvoice();

  useImperativeHandle(ref, () => ({
    save: async () => {
      if (!formRef.current) return;
      await formRef.current.save?.();
    },
  }));

  if (isLoading) return <Spinner inverted />;

  if (isError || !draftInvoice)
    return <div className="">{t("draftInvoiceEditor.error")}</div>;

  return (
    <DraftInvoiceEditorForm
      draftInvoice={draftInvoice}
      ref={formRef}
      onSave={onSave}
      onSubmit={onSubmit}
    />
  );
};

export default forwardRef(DraftInvoiceEditor);
