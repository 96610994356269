import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAuth from '../application/auth/useAuth';
import _fileService from './services/fileService';

const fileListQueryKey = 'fileList';

const useFileListRepository = (
  fileService = _fileService,
) => {
  const { user } = useAuth();

  const { isLoading, data: fileList, error: fetchFileListError, isFetching } = useQuery({
    queryKey: [fileListQueryKey],
    queryFn: () => fileService.getFiles(),
    enabled: !!user?.consentId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    refetchInterval: 10000,
  });

  const queryClient = useQueryClient();
  const refreshFileList = () => {
    queryClient.resetQueries([fileListQueryKey], { exact: true });
  };

  return {
    fileList,
    fileListIsLoading: isLoading,
    fileListIsFetching: isFetching,
    fetchFileListError,
    refreshFileList,
  };
};

export default useFileListRepository;
