import { Typography } from "@mui/material";
import styles from "./CommonPageLayout.module.scss";

type Props = {
  children: React.ReactNode;
  headerText: string;
  subheaderText: string;
  CTAButton?: React.ReactNode;
};

const CommonPageLayout = ({
  children,
  headerText,
  subheaderText,
  CTAButton,
}: Props) => (
  <div className={styles.page}>
    <div className={styles.headerContainer}>
      <Typography variant="h4" component="h1" className={styles.header}>
        {headerText}
      </Typography>
      {CTAButton}
    </div>
    {subheaderText && (
      <Typography paragraph className={styles.subheader}>
        {subheaderText}
      </Typography>
    )}
    {children}
  </div>
);

export default CommonPageLayout;
