import { SetupSftpForm } from "../../components";
import _useSftpConnection from "../../../application/useSftpConnection";
import CreateSftpConnectionDto from "../../../domain/models/CreateSftpConnectionDto";
import SftpConnectionDto from "../../../domain/models/SftpConnectionDto";

const ConnectToSftp = ({ useSftpConnection = _useSftpConnection }) => {
  const { useGetConnection, createConnection, isCreating, updateConnection, isUpdating } = useSftpConnection();
  const { connection, connectionIsFetching } = useGetConnection(true);

  const connectToSftp = async (values: SftpConnectionDto) => {
    if (!connection) {
      await createConnection(
        new CreateSftpConnectionDto(
          values.host,
          values.port,
          values.loginType,
          values.folderLocation,
          values.normalLogin,
          values.privateKeyLogin,
        ),
      );
    } else {
      await updateConnection({
        ...values,
        id: connection.id,
        etag: connection.etag,
      } as SftpConnectionDto);
    }
  };

  return (
    <SetupSftpForm
      isSubmitDisabled={isCreating || isUpdating}
      isSpinnerShown={connectionIsFetching}
      onSubmit={connectToSftp}
      connection={connection}
    />
  );
};

export default ConnectToSftp;
