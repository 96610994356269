import LoginType from "./LoginType";
import NormalLoginDto from "./NormalLoginDto";
import PrivateKeyLoginDto from "./PrivateKeyLoginDto";

export default class CreateSftpConnectionDto {
  host: string;

  port: number | undefined;

  loginType: LoginType;

  folderLocation: string;

  normalLogin: NormalLoginDto | undefined;

  privateKeyLogin: PrivateKeyLoginDto | undefined;

  constructor(
    host: string,
    port: number | undefined,
    loginType: LoginType,
    folderLocation: string,
    normalLogin: NormalLoginDto | undefined,
    privateKeyLogin: PrivateKeyLoginDto | undefined,
  ) {
    this.host = host;
    this.port = port;
    this.loginType = loginType;
    this.folderLocation = folderLocation;
    this.normalLogin = normalLogin;
    this.privateKeyLogin = privateKeyLogin;
  }
}
