import { useEffect, useState } from "react";
import useFileRepository from "../infrastructure/file.repository";
import UploadingFileProgress from "../domain/models/UploadingFileProgress";
import HttpRequestError from "../domain/exceptions/HttpRequestError";

const useFile = () => {
  const fileRepo = useFileRepository();

  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
  const [uploadingFileProgress, setUploadingFileProgress] = useState<
    UploadingFileProgress[]
  >([]);
  const [numberOfUploadingFiles, setNumberOfUploadingFiles] =
    useState<number>(0);

  const reset = () => {
    setUploadingFiles([]);
    setUploadingFileProgress([]);
    setNumberOfUploadingFiles(0);
  };

  const startFilesUploading = async (files: File[]) => {
    setUploadingFileProgress((prev) => [
      ...files.map((file) => ({
        name: file.name,
        progress: 0,
        error: null,
        finished: false,
      })),
      ...prev,
    ]);

    setUploadingFiles(files);
  };

  const handleFileUploadEvent = async (e) => {
    if (!e.target.files?.length) return;

    const files = Array.from(e.target.files as FileList);
    await startFilesUploading(files);
    e.target.value = null;
  };

  useEffect(() => {
    const onProgress = (fileIndex, progressEvent: ProgressEvent) => {
      const progress =
        progressEvent.total === progressEvent.loaded
          ? 100
          : Math.round((100 * progressEvent.loaded) / progressEvent.total);

      setUploadingFileProgress((prev) =>
        prev.map((file, index) => ({
          ...file,
          progress: index === fileIndex ? progress : file.progress,
        })),
      );
    };

    const onFinish = (fileIndex) => {
      setUploadingFileProgress((prev) =>
        prev.map((file, index) => ({
          ...file,
          finished: index === fileIndex ? true : file.finished,
        })),
      );
      setNumberOfUploadingFiles((prev) => prev - 1);
    };

    const onError = (fileIndex, error: string) => {
      setUploadingFileProgress((prev) =>
        prev.map((file, index) => ({
          ...file,
          error: index === fileIndex ? error : file.error,
        })),
      );
    };

    const upload = async (file, index) => {
      const uploadingFile = new FormData();
      uploadingFile.append("file", file);
      setNumberOfUploadingFiles((prev) => prev + 1);
      try {
        await fileRepo.uploadFile(
          uploadingFile,
          (progressEvent: ProgressEvent) => onProgress(index, progressEvent),
        );
        onFinish(index);
      } catch (e) {
        const exception = e as HttpRequestError;
        onError(index, exception.message);
        onFinish(index);
      }
    };

    const uploadFiles = async () => {
      const uploadPromises = uploadingFiles.map((file: Blob, index) =>
        upload(file, index),
      );
      await Promise.all(uploadPromises);
    };

    if (uploadingFiles.length) uploadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingFiles]);

  return {
    ...fileRepo,
    uploadingFileProgress,
    handleFileUploadEvent,
    startFilesUploading,
    numberOfUploadingFiles,
    reset,
  };
};

export default useFile;
