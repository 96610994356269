import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CircleProgressBar } from '@zwapgrid/zwapgrid-ui-component-library';
import UploadingFileProgress from '../../../domain/models/UploadingFileProgress';
import styles from './UploadingFilesToast.module.scss';

type UploadingFileLineProps = {
  fileProgress: UploadingFileProgress;
};

const UploadingFileLine = ({ fileProgress }: UploadingFileLineProps) => {
  const renderStatusIcon = () => {
    if (fileProgress.error) return null;

    return fileProgress.finished
      ? <FontAwesomeIcon icon={faCheck} className={styles.checkMark} />
      : <CircleProgressBar progress={fileProgress.progress} />;
  };

  const renderErrorMessage = () => {
    if (!fileProgress.error) return null;

    return (
      <span className={
        classNames(
          styles.error,
          styles.errorMessage,
          styles.lineClamp,
        )
        }
      >{fileProgress.error}
      </span>
    );
  };

  return (
    <span
      className={classNames(styles.fileLine, {
        [styles.error]: !!fileProgress.error,
      })}
    >
      <p className={styles.lineClamp}>
        {fileProgress.name}
      </p>
      {renderStatusIcon()}
      {renderErrorMessage()}
    </span>
  );
};
export default UploadingFileLine;
