import { StrictMode } from "react";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import "@zwapgrid/zwapgrid-ui-component-library/style.css";
import {
  LibraryThemeProvider,
  theme,
} from "@zwapgrid/zwapgrid-ui-component-library";
import { ThemeProvider } from "@mui/material";
import reportWebVitals from "./reportWebVitals";
import SnackbarConfig from "./constants/SnackbarConfig";
import Root from "./presentation/pages/Root";
import "./i18n";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <LibraryThemeProvider theme={theme}>
        <SnackbarProvider {...SnackbarConfig}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Root />
            </Router>
          </QueryClientProvider>
        </SnackbarProvider>
      </LibraryThemeProvider>
    </ThemeProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
