import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import useDraftInvoice from "../../../application/useDraftInvoice";
import { ReactComponent as SaveIcon } from "../../assets/SaveIcon.svg";
import styles from "./SaveButton.module.scss";

type Props = {
  onSave: () => void;
};

export const SaveButton = ({ onSave }: Props) => {
  const { isFetching, isUpdating } = useDraftInvoice();
  const { t } = useTranslation();
  const isDisabled = isFetching || isUpdating;

  return (
    <Button
      title={t("draftInvoiceEditor.form.saveProgressTitle")}
      className={styles.saveBtn}
      disabled={isDisabled}
      onClick={onSave}
      type="button"
      variant="elevatedContained"
    >
      <SaveIcon />
      {t("draftInvoiceEditor.form.saveProgress")}
    </Button>
  );
};

export default SaveButton;
