import { useTranslation } from "react-i18next";
import links from "../../../constants/links";

import styles from "./Footer.module.scss";

export const Footer = () => {
  const getLink = (href, title) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  );
  const { t } = useTranslation();
  return (
    <div className={styles.linksContainer}>
      {getLink(links.zwapgridLink, "Zwapgrid")}
      {getLink(links.securityLink, t("footer.security"))}
      {getLink(links.termsLink, t("footer.terms"))}
    </div>
  );
};

export default Footer;
