import api from '../../constants/api/consentsApi';
import httpClient from '../httpClient';
import ConsentDto from '../../domain/models/ConsentDto';

async function getConsent(
  consentId: string,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
):Promise<ConsentDto> {
  const requestOptions = {
    errorHandler,
    signal,
  };
  const response = await httpClient.instance.get<ConsentDto>(api.consent(consentId), requestOptions);
  return response.data;
}

export default {
  getConsent,
};
