import { Controller, Control, FieldPath } from "react-hook-form";
import { TextField, DatePicker } from "@zwapgrid/zwapgrid-ui-component-library";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { ReactNode } from "react";
import { DraftInvoiceFields } from "../../../domain/models/DraftInvoiceDto";

type Props = {
  label?: string;
  type: DraftInvoiceFieldTypes;
  name: FieldPath<DraftInvoiceFields>;
  error: boolean;
  control: Control<DraftInvoiceFields>;
  disableFuture?: boolean;
  className?: string;
  required?: boolean;
  onChange?: (
    handler: (event) => void,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  endAdornment?: ReactNode;
};

export const InputField = ({
  label,
  name,
  error,
  control,
  type,
  disableFuture = false,
  className,
  required = false,
  onChange = (handler) => handler,
  endAdornment,
}: Props) => {
  const { i18n } = useTranslation();
  return (
    <div className={className ?? "grow"}>
      {label && (
        <label htmlFor={name}>
          <Typography
            variant="body2"
            sx={{
              color: error ? "error.main" : "text.primary",
              fontWeight: error ? "bold" : "normal",
            }}
          >
            {label}
          </Typography>
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={{
          required,
        }}
        render={({ field: { value, ...restField } }) => {
          switch (type) {
            case "date":
              return (
                <DatePicker
                  {...restField}
                  onChange={(event) => restField.onChange(event)}
                  value={value ?? null}
                  disableFuture={disableFuture}
                  required
                  error={error}
                  color={value ? "success" : "primary"}
                  language={i18n.language.split("-")[0]}
                />
              );
            case "number":
              return (
                <TextField
                  {...restField}
                  type="number"
                  value={value ?? null}
                  onChange={onChange(restField.onChange)}
                  error={error}
                  color={value !== "" ? "success" : "primary"}
                  required={required}
                  fullWidth
                  InputProps={{
                    endAdornment: endAdornment ?? null,
                  }}
                />
              );
            case "boolean":
              return (
                <input
                  {...restField}
                  type="checkbox"
                  id={name}
                  name={name}
                  className="w-4 h-4 text-blue-600 rounded"
                  checked={value}
                  onChange={onChange(restField.onChange)}
                  required={required}
                />
              );
            case "text":
            default:
              return (
                <TextField
                  {...restField}
                  value={value}
                  onChange={onChange(restField.onChange)}
                  error={error}
                  color={value !== "" ? "success" : "primary"}
                  required={required}
                  fullWidth
                  InputProps={{
                    endAdornment: endAdornment ?? null,
                  }}
                />
              );
          }
        }}
      />
    </div>
  );
};

export default InputField;
