import classnames from "classnames";
import { useDropzone } from "react-dropzone";
import { Alert, AlertTitle, Snackbar } from "@mui/material";

import styles from "./DropZone.module.scss";
import { Box } from "../Box";

export const DropZone = ({
  onDrop,
  children,
  className,
  noClick = true,
  text,
  forceShowDropZone = false,
}: {
  onDrop: (acceptedFiles: File[]) => void;
  children: React.ReactNode;
  className?: string;
  noClick?: boolean;
  text?: {
    dropPrompt?: string;
  };
  forceShowDropZone?: boolean;
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick,
  });

  return (
    <div>
      <div {...getRootProps()} className={styles.dropZone}>
        <input {...getInputProps({})} />
        <Box>
          <div
            className={classnames(className, {
              [styles.isDragActive]: isDragActive || forceShowDropZone,
            })}
          >
            {children}
          </div>
        </Box>
      </div>
      {text?.dropPrompt && (
        <Snackbar
          open={isDragActive || forceShowDropZone}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            severity="info"
            className={styles.alert}
            sx={{ borderRadius: "0.75rem" }}
          >
            <AlertTitle>{text?.dropPrompt}</AlertTitle>
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default DropZone;
