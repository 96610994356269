import { ComponentClass, FunctionComponent, useEffect } from 'react';
import { Spinner } from '@zwapgrid/zwapgrid-ui-component-library';
import useAuth from './useAuth';
import useConsent from '../useConsent';

const ProtectedRoute = <P extends object>(
  Component: ComponentClass<P> | FunctionComponent<P>,
): FunctionComponent<P> => function ProtectedRouteInternal(props: P): JSX.Element {
    const { user, userIsFetching, redirectToUnauthorized } = useAuth();
    useConsent();

    const routeIsAuthenticated = user?.isAuthenticated;

    useEffect(() => {
      if (userIsFetching || routeIsAuthenticated) {
        return;
      }

      redirectToUnauthorized();
    }, [
      userIsFetching,
      routeIsAuthenticated,
      redirectToUnauthorized,
    ]);

    return routeIsAuthenticated ? <Component {...props} /> : <Spinner />;
  };

export default ProtectedRoute;
