import DraftInvoiceAddressDto, {
  DraftInvoiceAddressFields,
} from "./DraftInvoiceAddressDto";
import RecognizedFieldValueDto from "./RecognizedFieldValueDto";
import DraftInvoiceFieldStatus from "../../constants/draftInvoiceFieldStatus";

export type DraftInvoiceDeliveryFields = {
  name: string | null;
  date: Dayjs | null;
  address: DraftInvoiceAddressFields;
};

export type DraftInvoiceDeliveryFieldObject = {
  name: keyof DraftInvoiceDeliveryFields;
  status: DraftInvoiceFieldStatus;
  type: DraftInvoiceFieldTypes;
};

export default class DraftInvoiceDeliveryDto {
  public name: RecognizedFieldValueDto<string | null>;

  public date: RecognizedFieldValueDto<Date | null>;

  public address: DraftInvoiceAddressDto;

  public static readonly fields: ReadonlyArray<DraftInvoiceDeliveryFieldObject> =
    [
      {
        name: "name",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "text",
      },
      {
        name: "date",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "date",
      },
      {
        name: "address",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "object",
      },
    ] as const;

  constructor(
    name: RecognizedFieldValueDto<string | null> | null = null,
    date: RecognizedFieldValueDto<Date | null> | null = null,
    address: DraftInvoiceAddressDto | null = null,
  ) {
    this.name = name ?? new RecognizedFieldValueDto();
    this.date = date ?? new RecognizedFieldValueDto();
    this.address = address ?? new DraftInvoiceAddressDto();
  }

  public isValid(): boolean {
    return DraftInvoiceDeliveryDto.fields
      .filter((field) => field.status === DraftInvoiceFieldStatus.REQUIRED)
      .every((field) => this[field.name].isValid());
  }

  public static fromObject(obj): DraftInvoiceDeliveryDto {
    return new DraftInvoiceDeliveryDto(
      RecognizedFieldValueDto.fromObject(obj?.name),
      RecognizedFieldValueDto.fromObject(obj?.date),
      DraftInvoiceAddressDto.fromObject(obj?.address),
    );
  }

  private static DayjsToUtc = (date) =>
    date ? new Date(Date.UTC(date.year(), date.month(), date.date())) : null;

  public updateFromFormData(formData: DraftInvoiceDeliveryFields): this {
    this.name.updateFromFormData(formData.name);
    this.date.updateFromFormData(
      DraftInvoiceDeliveryDto.DayjsToUtc(formData.date),
    );
    this.address.updateFromFormData(formData.address);
    return this;
  }
}
