enum FileProcessingStatus
{
    Uploaded = 0,
    Processing = 1,
    Processed = 2,
    UserInput = 3,
    Expired = 4,
    Rejected = 5,
}

export default FileProcessingStatus;
