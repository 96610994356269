import { RecognizedFieldValueDto } from "./RecognizedFieldValueDto";
import {
  DraftInvoiceCustomerDto,
  DraftInvoiceCustomerFields,
} from "./DraftInvoiceCustomerDto";
import DraftInvoiceLineItemDto, {
  DraftInvoiceLineItemFields,
} from "./DraftInvoiceLineItemDto";
import DraftInvoiceDeliveryDto, {
  DraftInvoiceDeliveryFields,
} from "./DraftInvoiceDeliveryDto";
import DraftInvoiceSellerDto, {
  DraftInvoiceSellerFields,
} from "./DraftInvoiceSellerDto";
import DraftInvoiceFieldStatus from "../../constants/draftInvoiceFieldStatus";
import DraftInvoiceLineItems from "./DraftInvoiceLineItems";

export type DraftInvoiceFields = {
  reference: string | null;
  totalAmount: string | number | null;
  totalTaxAmount: string | number | null;
  issueDate: Dayjs | null;
  dueDate: Dayjs | null;
  buyerReference: string | null;
  orderReference: string | null;
  documentCurrencyCode: string | null;
  customer: DraftInvoiceCustomerFields;
  lineItems: DraftInvoiceLineItemFields[];
  creditIndicator: boolean | null;
  sellerOrderReference: string | null;
  paymentId: string | null;
  delivery: DraftInvoiceDeliveryFields;
  seller: DraftInvoiceSellerFields;
};

export type DraftInvoiceFieldObject = {
  name: keyof DraftInvoiceFields;
  status: DraftInvoiceFieldStatus;
  type: DraftInvoiceFieldTypes;
};

export class DraftInvoiceDto {
  public id: string;

  public etag: string;

  public uploadedFileId: string;

  public consentId: string;

  public createdOn: Date;

  public updatedOn: Date | null;

  public reference: RecognizedFieldValueDto<string | null>;

  public totalAmount: RecognizedFieldValueDto<number | null>;

  public totalTaxAmount: RecognizedFieldValueDto<number | null>;

  public issueDate: RecognizedFieldValueDto<Date | null>;

  public dueDate: RecognizedFieldValueDto<Date | null>;

  public buyerReference: RecognizedFieldValueDto<string | null>;

  public orderReference: RecognizedFieldValueDto<string | null>;

  public documentCurrencyCode: RecognizedFieldValueDto<string | null>;

  public customer: DraftInvoiceCustomerDto;

  public lineItems: DraftInvoiceLineItems;

  public creditIndicator: RecognizedFieldValueDto<boolean | null>;

  public sellerOrderReference: RecognizedFieldValueDto<string | null>;

  public paymentId: RecognizedFieldValueDto<string | null>;

  public delivery: DraftInvoiceDeliveryDto;

  public seller: DraftInvoiceSellerDto;

  public static readonly fields: ReadonlyArray<DraftInvoiceFieldObject> = [
    {
      name: "customer",
      status: DraftInvoiceFieldStatus.REQUIRED,
      type: "object",
    },
    {
      name: "delivery",
      status: DraftInvoiceFieldStatus.OPTIONAL,
      type: "object",
    },
    {
      name: "creditIndicator",
      status: DraftInvoiceFieldStatus.OPTIONAL,
      type: "boolean",
    },
    {
      name: "reference",
      status: DraftInvoiceFieldStatus.REQUIRED,
      type: "text",
    },
    {
      name: "paymentId",
      status: DraftInvoiceFieldStatus.OPTIONAL,
      type: "text",
    },
    {
      name: "buyerReference",
      status: DraftInvoiceFieldStatus.OPTIONAL,
      type: "text",
    },
    {
      name: "orderReference",
      status: DraftInvoiceFieldStatus.OPTIONAL,
      type: "text",
    },
    {
      name: "seller",
      status: DraftInvoiceFieldStatus.OPTIONAL,
      type: "object",
    },
    {
      name: "sellerOrderReference",
      status: DraftInvoiceFieldStatus.OPTIONAL,
      type: "text",
    },
    {
      name: "issueDate",
      status: DraftInvoiceFieldStatus.REQUIRED,
      type: "date",
    },
    {
      name: "dueDate",
      status: DraftInvoiceFieldStatus.REQUIRED,
      type: "date",
    },
    {
      name: "documentCurrencyCode",
      status: DraftInvoiceFieldStatus.REQUIRED,
      type: "text",
    },
    {
      name: "lineItems",
      status: DraftInvoiceFieldStatus.OPTIONAL,
      type: "array",
    },
    {
      name: "totalTaxAmount",
      status: DraftInvoiceFieldStatus.REQUIRED,
      type: "number",
    },
    {
      name: "totalAmount",
      status: DraftInvoiceFieldStatus.REQUIRED,
      type: "number",
    },
  ] as const;

  constructor(
    id: string,
    etag: string,
    uploadedFileId: string,
    consentId: string,
    createdOn: Date,
    updatedOn: Date | null,
    reference: RecognizedFieldValueDto<string | null>,
    totalAmount: RecognizedFieldValueDto<number | null>,
    totalTaxAmount: RecognizedFieldValueDto<number | null>,
    issueDate: RecognizedFieldValueDto<Date | null>,
    dueDate: RecognizedFieldValueDto<Date | null>,
    buyerReference: RecognizedFieldValueDto<string | null>,
    orderReference: RecognizedFieldValueDto<string | null>,
    documentCurrencyCode: RecognizedFieldValueDto<string | null>,
    customer: DraftInvoiceCustomerDto,
    lineItems: DraftInvoiceLineItems | DraftInvoiceLineItemDto[],
    creditIndicator: RecognizedFieldValueDto<boolean | null> | null = null,
    sellerOrderReference: RecognizedFieldValueDto<string | null> | null = null,
    paymentId: RecognizedFieldValueDto<string | null> | null = null,
    delivery: DraftInvoiceDeliveryDto | null = null,
    seller: DraftInvoiceSellerDto | null = null,
  ) {
    this.id = id;
    this.etag = etag;
    this.uploadedFileId = uploadedFileId;
    this.consentId = consentId;
    this.createdOn = new Date(createdOn);
    this.updatedOn = updatedOn ? new Date(updatedOn) : null;
    this.reference = reference;
    this.totalAmount = totalAmount;
    this.totalTaxAmount = totalTaxAmount;
    this.issueDate = issueDate;
    this.dueDate = dueDate;
    this.buyerReference = buyerReference;
    this.orderReference = orderReference;
    this.documentCurrencyCode = documentCurrencyCode;
    this.customer = customer;
    this.lineItems = DraftInvoiceLineItems.fromObject(lineItems);
    this.creditIndicator =
      creditIndicator ?? new RecognizedFieldValueDto<boolean | null>();
    this.sellerOrderReference =
      sellerOrderReference ?? new RecognizedFieldValueDto<string | null>();
    this.paymentId = paymentId ?? new RecognizedFieldValueDto<string | null>();
    this.delivery = delivery ?? new DraftInvoiceDeliveryDto();
    this.seller = seller ?? new DraftInvoiceSellerDto();
  }

  public isValid(): boolean {
    return DraftInvoiceDto.fields
      .filter((field) => field.status === DraftInvoiceFieldStatus.REQUIRED)
      .every((field) => this[field.name].isValid());
  }

  static createFromObject(obj: DraftInvoiceDto): DraftInvoiceDto {
    return new DraftInvoiceDto(
      obj.id,
      obj.etag,
      obj.uploadedFileId,
      obj.consentId,
      obj.createdOn,
      obj.updatedOn,
      RecognizedFieldValueDto.fromObject(obj.reference),
      RecognizedFieldValueDto.fromObject(obj.totalAmount),
      RecognizedFieldValueDto.fromObject(obj.totalTaxAmount),
      RecognizedFieldValueDto.fromObject(obj.issueDate),
      RecognizedFieldValueDto.fromObject(obj.dueDate),
      RecognizedFieldValueDto.fromObject(obj.buyerReference),
      RecognizedFieldValueDto.fromObject(obj.orderReference),
      RecognizedFieldValueDto.fromObject(obj.documentCurrencyCode),
      DraftInvoiceCustomerDto.fromObject(obj.customer),
      DraftInvoiceLineItems.fromObject(obj.lineItems),
      RecognizedFieldValueDto.fromObject(obj.creditIndicator),
      RecognizedFieldValueDto.fromObject(obj.sellerOrderReference),
      RecognizedFieldValueDto.fromObject(obj.paymentId),
      DraftInvoiceDeliveryDto.fromObject(obj.delivery),
      DraftInvoiceSellerDto.fromObject(obj.seller),
    );
  }

  private static DayjsToUtc = (date) =>
    date ? new Date(Date.UTC(date.year(), date.month(), date.date())) : null;

  public updateFromFormData(formData: DraftInvoiceFields): this {
    this.updatedOn = new Date();
    this.reference.updateFromFormData(formData.reference);
    this.totalAmount.updateFromFormData(
      formData.totalAmount === "" ? null : Number(formData.totalAmount),
    );
    this.totalTaxAmount.updateFromFormData(
      formData.totalTaxAmount === "" ? null : Number(formData.totalTaxAmount),
    );
    this.issueDate.updateFromFormData(
      DraftInvoiceDto.DayjsToUtc(formData.issueDate),
    );
    this.dueDate.updateFromFormData(
      DraftInvoiceDto.DayjsToUtc(formData.dueDate),
    );

    this.buyerReference.updateFromFormData(formData.buyerReference);
    this.orderReference.updateFromFormData(formData.orderReference);
    this.documentCurrencyCode.updateFromFormData(formData.documentCurrencyCode);
    this.customer.updateFromFormData(formData.customer);
    this.lineItems.updateFromFormData(formData.lineItems);
    this.creditIndicator.updateFromFormData(formData.creditIndicator);
    this.sellerOrderReference.updateFromFormData(formData.sellerOrderReference);
    this.paymentId.updateFromFormData(formData.paymentId);
    this.delivery.updateFromFormData(formData.delivery);
    this.seller.updateFromFormData(formData.seller);

    return this;
  }
}

export default DraftInvoiceDto;
