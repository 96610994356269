import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, Typography } from "@mui/material";
import styles from "./UploadFilesButton.module.scss";

type Props = {
  onClick: () => void;
  onUpload?: (event: ChangeEvent) => void;
  disabled: boolean;
  dialogOpen: boolean;
};

const UploadFilesButton = ({
  onClick,
  disabled,
  onUpload,
  dialogOpen,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.uploadContainer}>
      <Tooltip
        arrow
        placement="right"
        title={t("uploadFilesButton.uploadFilesRetentionDisclaimer")}
      >
        <div>
          <input
            id="uploadBtn"
            className={styles.uploadInput}
            onClick={onClick}
            disabled={disabled}
            type="file"
            accept=".pdf, .xml"
            onChange={onUpload}
            multiple
          />
          <label
            className={classNames({
              [styles.disabled]: disabled || dialogOpen,
            })}
            htmlFor="uploadBtn"
          >
            <FontAwesomeIcon icon={faPlus} />
            {t("uploadFilesButton.uploadFiles")}
          </label>
        </div>
      </Tooltip>
      <Typography paragraph className={styles.uploadText}>
        {t("uploadFilesButton.uploadFilesDescription")}
      </Typography>
    </div>
  );
};

export default UploadFilesButton;
