import useRedirect from './useRedirect';
import useAuth from './useAuth';

const useLogout = () => {
  const { redirectUrl } = useRedirect();
  const { logout } = useAuth();

  const logoutWithRedirect = () => logout(redirectUrl);

  return { logoutWithRedirect };
};

export default useLogout;
