import { useCallback, useState } from "react";
import {
  Collapse,
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import UploadingFileProgress from "../../../domain/models/UploadingFileProgress";
import styles from "./UploadingFilesToast.module.scss";
import UploadingFileLine from "./UploadingFileLine.component";

interface Props {
  files: UploadingFileProgress[];
  numberOfUploadingFiles: number;
  open: boolean;
  onClose: () => void;
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const UploadingFilesToast = ({
  files,
  numberOfUploadingFiles,
  onClose,
  open,
}: Props) => {
  const [expanded, setExpanded] = useState(true);
  const { t } = useTranslation();
  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const renderFile = (file: UploadingFileProgress, index) => {
    if (!file) return null;

    return <UploadingFileLine key={index} fileProgress={file} />;
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={SlideTransition}
    >
      <div className={styles.toastCard}>
        <section className={styles.toastHeaderContainer}>
          <Typography paragraph className={styles.header}>
            {t("uploadingFilesToast.uploadingFiles", {
              count: numberOfUploadingFiles,
            })}
          </Typography>
          <span className={styles.toastIconContainer}>
            <IconButton
              className={classNames(styles.expand, {
                [styles.expandOpen]: expanded,
              })}
              aria-label="show more"
              onClick={handleExpandClick}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </IconButton>
            <IconButton
              aria-label="close"
              disabled={numberOfUploadingFiles > 0}
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          </span>
        </section>
        <Collapse in={expanded} className={styles.toastContent}>
          <div className={styles.toastScrollContainer}>
            {files?.map((file, index) => renderFile(file, index))}
          </div>
        </Collapse>
      </div>
    </Snackbar>
  );
};

export default UploadingFilesToast;
