import { useParams, useNavigate } from "react-router-dom";
import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";

import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import _useFile from "../../../application/useFile";
import FileViewer from "../../components/FileViewer";
import styles from "./FileViewerPage.module.scss";
import { ReactComponent as BackIcon } from "../../assets/BackIcon.svg";
import {
  DraftInvoiceEditor,
  DraftInvoiceEditorFormRef,
  SaveButton,
} from "../../components/DraftInvoiceEditor";

// We have used wrappers here to avoid having FileViewer reload at each state change in the useDraftInvoice hook.
// Same goes for the Save button.

const FileViewerPage = ({ useFile = _useFile }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { fileId } = params;
  const { t } = useTranslation();
  const { useGetFile } = useFile();
  const { file, fileIsFetching } = useGetFile(fileId);
  const formRef = useRef<DraftInvoiceEditorFormRef>(null);

  const fileOptions = useMemo(() => {
    if (!file) return null;

    const fileStoragePath = file.storagePath.replace("v1/", "");
    return {
      url: `/${fileStoragePath}`,
      httpHeaders: {
        "x-correlation-id": crypto.randomUUID(),
      },
    };
  }, [file]);

  if (!fileId) return null;
  const onSave = () => {
    if (!formRef.current) return;
    formRef.current?.save();
  };

  const onSubmit = () => {
    navigate("/files");
  };

  const renderFileViewer = () => {
    if (fileIsFetching) {
      return <Spinner absolute inverted />;
    }

    return <FileViewer fileType={file?.fileType} fileOptions={fileOptions} />;
  };

  return (
    <div className={styles.fileViewerPage}>
      <div className={styles.controlsContainer}>
        <Button
          className={styles.backBtn}
          title={t("fileViewerPage.backButtonTitle")}
          onClick={() => navigate("/files")}
        >
          <BackIcon />
          {t("common.back")}
        </Button>
        <SaveButton onSave={onSave} />
      </div>

      <div className={styles.editorContainer}>
        {renderFileViewer()}
        <DraftInvoiceEditor ref={formRef} onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default FileViewerPage;
