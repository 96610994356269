import { SyntheticEvent, useEffect, useState } from "react";
import { useForm} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { Spinner, TextField } from "@zwapgrid/zwapgrid-ui-component-library";
import { Box as File1Box, CommonPageLayout, TabPanel } from "../index";
import SftpConnectionDto from "../../../domain/models/SftpConnectionDto";
import LoginType from "../../../domain/models/LoginType";
import NormalLoginDto from "../../../domain/models/NormalLoginDto";
import PrivateKeyLoginDto from "../../../domain/models/PrivateKeyLoginDto";

type Props = {
  onSubmit: (formData: {
    folderLocation: string;
    normalLogin: NormalLoginDto | undefined;
    port: number | undefined;
    loginType: LoginType;
    privateKeyLogin: PrivateKeyLoginDto | undefined;
    host: string;
    etag: string;
    id: string
  }) => Promise<void>;
  isSpinnerShown: boolean;
  isSubmitDisabled: boolean;
  connection?: SftpConnectionDto
};

const SetupSftpForm = ({ onSubmit, isSpinnerShown, isSubmitDisabled, connection}: Props) => {
  const [tabPanelIndex, setTabPanelIndex] = useState(0);
  const { t } = useTranslation();

  const pageLayoutParams = {
    headerText: t("sftp.headerText"),
    subheaderText: "",
  };

  const isValidTabField = (value: string | undefined | null, index: number ) =>
    tabPanelIndex === index ? !!value && /[^\t\n\r ]/.test(value) : true;

  const defaultValues = {
    port: 22,
    loginType: LoginType.NormalLogin,
  };

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    reset,
    trigger,
    register,
    setValue,
  } = useForm<SftpConnectionDto>({ mode: "onChange", defaultValues });

  const submitConnection = async (values: SftpConnectionDto) => {
    const formData = {...values};
    if (formData.loginType === LoginType.NormalLogin) {
      formData.privateKeyLogin = undefined;
      setValue("privateKeyLogin.username", "");
      setValue("privateKeyLogin.keyContent", "");
      setValue("privateKeyLogin.keyPassphrase", "");
    } else {
      formData.normalLogin = undefined;
      setValue("normalLogin.username", "");
      setValue("normalLogin.password", "");
    }

    if (!formData.port && formData.port !== 0) {
      formData.port = undefined;
    }

    try {
      await onSubmit(formData);
    } catch (error) {
      reset(values);
    }
  };

  useEffect(() => {
    if (connection) {
      reset({...connection}, {keepDirtyValues: true});
      setTabPanelIndex(connection.loginType);
      setValue("loginType", connection.loginType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  const selectLoginType = async (_: SyntheticEvent, newValue: number) => {
    setTabPanelIndex(newValue);
    setValue("loginType", newValue);
    await trigger();
  };

  const renderSpinner = () => isSpinnerShown || isSubmitting ? (<Spinner/>) : null;

  return (
    <>
      {renderSpinner()}
      <CommonPageLayout {...pageLayoutParams}>
        <File1Box>
          <Box
            sx={{
              width: "70%",
              margin: "auto",
              bgcolor: "background.paper",
            }}
          >
            <form
              style={{ display: "grid" }}
              onSubmit={handleSubmit(submitConnection)}
            >
              <label htmlFor="host">
                <Typography gutterBottom>{t("sftp.host")}</Typography>
              </label>
              <TextField
                fullWidth
                variant="outlined"
                title={t("sftp.host")}
                sx={{ marginBottom: "1rem" }}
                color={!errors.host ? "success" : "info"}
                {...register("host", { required: true, pattern: /[^\t\n\r ]/ })}
              />
              <label htmlFor="port">
                <Typography gutterBottom>{t("sftp.port")}</Typography>
              </label>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                title={t("sftp.port")}
                sx={{ marginBottom: "1rem" }}
                color="info"
                {...register("port")}
              />
              <Tabs value={tabPanelIndex} onChange={selectLoginType}>
                <Tab label={t("sftp.loginType.normal")} />
                <Tab label={t("sftp.loginType.privateKey")} />
              </Tabs>
              <TabPanel value={tabPanelIndex} index={LoginType.NormalLogin}>
                <label htmlFor="usernameNormalLogin">
                  <Typography gutterBottom>
                    {t("sftp.usernameNormalLogin")}
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  title={t("sftp.usernameNormalLogin")}
                  sx={{ marginBottom: "1rem" }}
                  color={!errors.normalLogin?.username ? "success" : "info"}
                  {...register("normalLogin.username", {
                    validate: (value) =>
                      isValidTabField(value, LoginType.NormalLogin),
                  })}
                />
                <label htmlFor="password">
                  <Typography gutterBottom>{t("sftp.password")}</Typography>
                </label>
                <TextField
                  fullWidth
                  type="password"
                  variant="outlined"
                  title={t("sftp.password")}
                  sx={{ marginBottom: "1rem" }}
                  color={!errors.normalLogin?.password ? "success" : "info"}
                  {...register("normalLogin.password", {
                    validate: (value) =>
                      isValidTabField(value, LoginType.NormalLogin),
                  })}
                />
              </TabPanel>
              <TabPanel value={tabPanelIndex} index={LoginType.PrivateKeyLogin}>
                <label htmlFor="usernamePrivateKeyLogin">
                  <Typography gutterBottom>
                    {t("sftp.usernamePrivateKeyLogin")}
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  title={t("sftp.usernamePrivateKeyLogin")}
                  sx={{ marginBottom: "1rem" }}
                  color={!errors.privateKeyLogin?.username ? "success" : "info"}
                  {...register("privateKeyLogin.username", {
                    validate: (value) =>
                      isValidTabField(value, LoginType.PrivateKeyLogin),
                  })}
                />
                <label htmlFor="keyContent">
                  <Typography gutterBottom>{t("sftp.keyContent")}</Typography>
                </label>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  variant="outlined"
                  title={t("sftp.keyContent")}
                  sx={{ marginBottom: "0.5rem" }}
                  color={
                    !errors.privateKeyLogin?.keyContent ? "success" : "info"
                  }
                  {...register("privateKeyLogin.keyContent", {
                    validate: (value) =>
                      isValidTabField(value, LoginType.PrivateKeyLogin),
                  })}
                />
                <Typography variant="body2" gutterBottom>
                  {t("sftp.kepPassphraseHelpText")}
                </Typography>
                <label htmlFor="keyPassphrase">
                  <Typography gutterBottom>
                    {t("sftp.keyPassphrase")}
                  </Typography>
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="password"
                  title={t("sftp.keyPassphrase")}
                  sx={{ marginBottom: "0.5rem" }}
                  color="info"
                  {...register("privateKeyLogin.keyPassphrase")}
                />
                <Typography variant="body2" gutterBottom>
                  {t("sftp.folderLocationHelpText")}
                </Typography>
              </TabPanel>
              <label htmlFor="folderLocation">
                <Typography gutterBottom>{t("sftp.folderLocation")}</Typography>
              </label>
              <TextField
                fullWidth
                variant="outlined"
                title={t("sftp.folderLocation")}
                sx={{ marginBottom: "1rem" }}
                color="info"
                {...register("folderLocation")}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{ alignSelf: "center" }}
                disabled={!isValid || isSubmitDisabled}
              >
                {t("sftp.testAndSubmit")}
              </Button>
            </form>
          </Box>
        </File1Box>
      </CommonPageLayout>
    </>
  );
}

export default SetupSftpForm;