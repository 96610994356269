import RecognizedFieldValueDto from "./RecognizedFieldValueDto";
import DraftInvoiceFieldStatus from "../../constants/draftInvoiceFieldStatus";

export type DraftInvoiceSellerFields = {
  contactName: string | null;
};

export type DraftInvoiceSellerFieldObject = {
  name: keyof DraftInvoiceSellerFields;
  status: DraftInvoiceFieldStatus;
  type: DraftInvoiceFieldTypes;
};

export default class DraftInvoiceSellerDto {
  public contactName: RecognizedFieldValueDto<string | null>;

  public static readonly fields: ReadonlyArray<DraftInvoiceSellerFieldObject> =
    [
      {
        name: "contactName",
        status: DraftInvoiceFieldStatus.OPTIONAL,
        type: "text",
      },
    ] as const;

  constructor(
    contactName: RecognizedFieldValueDto<string | null> | null = null,
  ) {
    this.contactName = contactName ?? new RecognizedFieldValueDto();
  }

  public static fromObject(obj): DraftInvoiceSellerDto {
    return new DraftInvoiceSellerDto(
      RecognizedFieldValueDto.fromObject(obj.contactName),
    );
  }

  public isValid(): boolean {
    return DraftInvoiceSellerDto.fields
      .filter((field) => field.status === DraftInvoiceFieldStatus.REQUIRED)
      .every((field) => this[field.name].isValid());
  }

  public updateFromFormData(formData: DraftInvoiceSellerFields): this {
    this.contactName.updateFromFormData(formData.contactName);
    return this;
  }
}
