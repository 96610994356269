export default class UploadFileMutationDto {
  file: FormData;

  progressCallBack: ((progressEvent: ProgressEvent) => void) | undefined;

  errorCallBack: ((progressEvent: ProgressEvent) => void) | undefined;

  constructor(
    file: FormData,
    progressCallBack: ((progressEvent: ProgressEvent) => void) | undefined = undefined,
  ) {
    this.file = file;
    this.progressCallBack = progressCallBack;
  }
}
