import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileType from "../../../domain/models/FileType";

import styles from "./FileViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.mjs";

const FileViewer = ({ fileType, fileOptions }) => {
  const { t } = useTranslation();
  const [documentPagesNumber, setDocumentPagesNumber] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfScaleValue, setPdfScaleValue] = useState(1);

  const onDocumentLoad = ({ numPages }) => setDocumentPagesNumber(numPages);

  const openPreviousPage = () => setPageNumber(Math.max(pageNumber - 1, 1));
  const openNextPage = () => setPageNumber(Math.min(pageNumber + 1, documentPagesNumber ?? 0));

  const zoomIn = () => pdfScaleValue < 3.0 && setPdfScaleValue(pdfScaleValue + 0.2);
  const zoomOut = () => pdfScaleValue > 1.0 && setPdfScaleValue(pdfScaleValue - 0.2);
  const setPdfScaleValueFromViewport = () => {
    const minViewport = 1200;
    const maxViewport = 2400;
    const minScale = 1;
    const maxScale = 1.6;
    const ratio = (window.innerWidth - minViewport) / (maxViewport - minViewport);

    setPdfScaleValue(minScale + ratio * (maxScale - minScale));
  }

  useEffect(() => {
    setPdfScaleValueFromViewport();
    window.addEventListener('resize', setPdfScaleValueFromViewport);

    return () => window.removeEventListener('resize', setPdfScaleValueFromViewport);
  }, []);

  const renderFileContent = () => {
    if (fileType !== FileType.Pdf) {
      return <>{t("draftInvoiceEditor.viewer.notSupported")}</>;
    }

    return (
      <>
        <div className={styles.zoomControls}>
          <IconButton aria-label="zoom-in" size="medium" color="primary"
            onClick={zoomIn}
          >
            <ZoomInIcon fontSize="inherit" />
          </IconButton>
          <IconButton aria-label="zoom-out" size="medium" color="primary"
            onClick={zoomOut}
          >
            <ZoomOutIcon fontSize="inherit" />
          </IconButton>
          <IconButton aria-label="zoom-reset" size="medium" color="primary"
            onClick={setPdfScaleValueFromViewport}
          >
            <RestartAltIcon fontSize="inherit" />
          </IconButton>
        </div>
        <Document
          className="m-auto"
          file={fileOptions}
          onLoadSuccess={onDocumentLoad}
          loading={<div className="h-[80vh] mt-20 text-3xl text-center">Loading pdf ...</div>}
        >
          <Page
            scale={pdfScaleValue}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            loading={<div className="h-[80vh] mt-20 text-3xl text-center">Loading pdf ...</div>}
          />
        </Document>
        <div className={styles.pageControls}>
          <IconButton
            aria-label="previous-page"
            size="medium"
            color="primary"
            title={t("draftInvoiceEditor.viewer.previousButtonTitle")}
            onClick={openPreviousPage}
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <span>{`${pageNumber} ${t(
            "draftInvoiceEditor.viewer.ofPage",
          )} ${documentPagesNumber}`}</span>
          <IconButton
            aria-label="next-page"
            size="medium"
            color="primary"
            title={t("draftInvoiceEditor.viewer.nextButtonTitle")}
            onClick={openNextPage}
          >
            <ArrowForwardIcon fontSize="inherit" />
          </IconButton>
        </div>
      </>
    );
  };

  return (
    <div className={styles.fileViewerContainer}>{renderFileContent()}</div>
  );
};

export default FileViewer;
