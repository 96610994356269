import DraftInvoiceLineItemDto, {
  DraftInvoiceLineItemFields,
} from "./DraftInvoiceLineItemDto";

export class DraftInvoiceLineItems extends Array<DraftInvoiceLineItemDto> {
  public isValid(): boolean {
    return this.every((item) => item.isValid());
  }

  public static fromObject(
    obj: DraftInvoiceLineItemDto[] | DraftInvoiceLineItems,
  ): DraftInvoiceLineItems {
    const res = new DraftInvoiceLineItems();
    obj.forEach((item) => res.push(item));
    return res;
  }

  public updateFromFormData(formData: DraftInvoiceLineItemFields[]): this {
    this.length = formData.length;
    formData.forEach((item, index) => {
      if (index < this.length && this[index] instanceof DraftInvoiceLineItemDto)
        this[index].updateFromFormData(item);
      else {
        const lineItem = DraftInvoiceLineItemDto.createFromFormData(item);
        if (lineItem !== null) this[index] = lineItem;
      }
    });
    this.pop();
    return this;
  }
}

export default DraftInvoiceLineItems;
