import { NavLink, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./NavigationBar.module.scss";
import _useFeatureList from "../../../application/useFeatureList";

const NavigationBar = ({ isOpened, useFeatureList = _useFeatureList }) => {
  const { t } = useTranslation();
  const { features } = useFeatureList();
  const [urlParams] = useSearchParams();

  type NavigationLink = {
    id: string;
    route: string;
    text: string;
    isExact: boolean;
  };

  const navigationLinks: NavigationLink[] = [
    {
      id: "fileone-myFiles",
      route: "/files",
      text: t("routes.myFiles"),
      isExact: true,
    },
    {
      id: "fileone-sftp-connection",
      route: "/sftp",
      text: t("routes.sftp"),
      isExact: true,
    },
  ];
  const filterRoutes = (id: string) => {
    if (!features) {
      return true;
    }

    if (features.findIndex((f) => f.key === id) === -1) {
      return true;
    }

    return features.find((f) => f.key === id)?.value;
  };

  const filteredLinks = navigationLinks.filter((l) => filterRoutes(l.id));

  const renderNavigationLink = (navigationLink: NavigationLink) => (
    <NavLink
      key={navigationLink.id}
      className={(navData) =>
        classNames(styles.navigationLink, {
          [styles.active]: navData.isActive,
        })
      }
      to={{ pathname: navigationLink.route, search: urlParams.toString() }}
      end={navigationLink.isExact}
    >
      {navigationLink.text}
    </NavLink>
  );

  const renderNavigationLinks = () => (
    <div className={styles.navigationLinksContainer}>
      {filteredLinks.map(renderNavigationLink)}
    </div>
  );

  return (
    <div
      className={classNames(styles.navigationBar, {
        [styles.isOpen]: isOpened,
      })}
    >
      <div className={styles.avatarContainer} />
      {renderNavigationLinks()}
    </div>
  );
};

export default NavigationBar;
